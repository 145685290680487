import { registryModule } from '@boost-sd/components-registry/registry';
import { buildUrlWithLocalization } from '@utils';

const PATH = '/cart';

export const CartAPI = registryModule('CartAPI', {
  addToCart: async (id: string, quantity: number) => {
    const payload = {
      items: [
        {
          id,
          quantity,
        },
      ],
    };

    return await fetch(`${PATH}/add.js`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
    });
  },

  addMultiProductToCart: async (data: Array<{ id: string; quantity: number }>) => {
    const payload = {
      items: data,
    };

    return await fetch(`${PATH}/add.js`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
    });
  },

  getCartItems: async () => {
    const response = await fetch(`${buildUrlWithLocalization('cart')}.js`, { method: 'GET' });

    return await response.json();
  },

  clearCart: async () => {
    const response = await fetch(`${PATH}/clear.js`, { method: 'POST' });

    return await response.json();
  },

  changeCartItem: async (key: string, quantity: number) => {
    const payload = {
      id: key,
      quantity,
    };

    const response = await fetch(`${PATH}/change.js`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
    });

    return await response.json();
  },
});
