import type { ButtonProps } from '@components/Button';
import Button from '@components/Button';

export type QuickViewButtonProps = ButtonProps & {
  text?: string;
  backgroundColor?: string;
  backgroundColorOnHover?: string;
  borderColor?: string;
  borderColorOnHover?: string;
  textColor?: string;
  textColorOnHover?: string;
  shape?: 'rectangle' | 'round';
  fontFamily?: string;
  fontStyle?: string;
  fontSize?: number;
  fontWeight?: string | number;
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
};

const QuickViewButton = ({
  text,
  shape,
  backgroundColor,
  backgroundColorOnHover,
  borderColor,
  borderColorOnHover,
  textColor,
  textColorOnHover,
  fontFamily,
  fontStyle,
  fontSize,
  fontWeight,
  textTransform,
  ...props
}: QuickViewButtonProps) => {
  const defaultStyle = {
    fontStyle: fontStyle,
    fontSize: fontSize,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    textTransform: textTransform,
    color: textColor,
    borderColor: borderColor,
    background: backgroundColor,
  };

  const handleHoverButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (textColorOnHover) e.currentTarget.style.color = textColorOnHover;
    if (borderColorOnHover) e.currentTarget.style.borderColor = borderColorOnHover;
    if (backgroundColorOnHover) e.currentTarget.style.backgroundColor = backgroundColorOnHover;
  };

  const handleBlurButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (textColor) e.currentTarget.style.color = textColor;
    if (borderColor) e.currentTarget.style.borderColor = borderColor;
    if (backgroundColor) e.currentTarget.style.backgroundColor = backgroundColor;
  };

  return (
    <Button
      onMouseEnter={handleHoverButton}
      onMouseLeave={handleBlurButton}
      style={defaultStyle}
      buttonModifiers={{
        round: shape === 'round',
        border: true,
        large: true,
        'half-width': true,
        truncate: true,
      }}
      {...props}
    >
      {textTransform === 'none' ? text : text?.toLowerCase()}
    </Button>
  );
};

export default QuickViewButton;
