import { useCallback, useEffect } from 'react';

export const useMountEffect = (effect: () => React.EffectCallback | Promise<void> | void) => {
  const mountEffect = useCallback(() => {
    effect();
  }, [effect]);

  useEffect(mountEffect, []);
};

export default useMountEffect;
