import './ProductImage.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { composeRef, useDevicePixelRatio } from '@boost-sd/core-js';
import type { CTAButtonProps } from '@components/CallToActionButton';
import Image from '@components/Image';
import type { IntegrationProductRatingProps } from '@components/IntegrationProductRating';
import IntegrationProductWishlist from '@components/IntegrationProductWishlist';
import type { ProductLabelProps } from '@components/ProductLabel';
import type { ProductSwatchProps } from '@components/ProductSwatch';
import type { ProductImageGrid, ProductImageGridRow } from '@providers/ThemeProvider/types';
import type { ModifiersType } from '@utils';
import { createClsNameMap, mapModifiers, mergeModifiers, stripHtml } from '@utils';
import { castArray } from 'lodash-es';
import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const clsNameMap = createClsNameMap({
  elements: {
    wrapper: createClsNameMap({
      modifiers: [
        'square',
        'aspect-ratio-3-4',
        'portrait',
        'landscape',
        'natural',
        'manual',
        'auto',
        'full-box',
        'crop-top',
        'crop-center',
        'crop-bottom',
        'has-second-image',
        'zoom-in',
      ],
    }),
    img: createClsNameMap({
      modifiers: ['main', 'display-block', 'second', 'as-slider'],
    }),
    row: createClsNameMap({
      modifiers: ['top', 'middle', 'bottom', 'vertical'],
    }),
    column: createClsNameMap({
      modifiers: ['in-top', 'in-middle', 'in-bottom', 'center', 'right', 'left'],
    }),
  },
  modifiers: ['has-border', 'blur'],
})('product-image');

export type ProductImageProps = {
  elements?: {
    saleLabel?: React.ReactElement<ProductLabelProps>;
    soldOutLabel?: React.ReactElement<ProductLabelProps>;
    customLabelByTag?: React.ReactElement<ProductLabelProps>;
    inventoryStatus?: React.ReactElement;
    [swatches: `swatches.${number}`]: React.ReactElement<ProductSwatchProps> | undefined;
    qvBtn?: React.ReactElement<CTAButtonProps>;
    selectOptionBtn?: React.ReactElement<CTAButtonProps>;
    goToDetailBtn?: React.ReactElement<CTAButtonProps>;
    // 3rd App Integration
    rating?: React.ReactElement<IntegrationProductRatingProps>;
  };
  grid?: ProductImageGrid;
  images: string[] | string;
  originalSize?: Array<{
    width: number;
    height: number;
  }>;
  aspectRatio?:
    | 'square'
    | 'aspect-ratio-3-4'
    | 'portrait'
    | 'landscape'
    | 'natural'
    | 'manual'
    | 'auto'
    | 'full-box';
  cropPosition?: 'top' | 'center' | 'bottom';
  altImage?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  isImageSlider?: boolean;
  isAutoRatio?: boolean;
  manualAspectRatio?: `${number}/${number}`;
  autoAspectRatio?: `${number}/${number}`;
  naturalAspectRatio?: `${number}/${number}`;
  hoverEffect?: 'reveal-second-image' | 'zoom-in' | 'reveal-second-image-and-zoom-in';
  imageModifiers?: ModifiersType<typeof clsNameMap, true>;
  productId?: number;
  displayBlock?: boolean;
  /**
   * @default "original"
   */
  loadedImageSizeStrategy?: 'original' | 'fit-container' | 'fit-container-with-dpi';
  isShowWishlist?: boolean;
  /**
   * Define device sizes to generate srcSet
   */
  deviceSizes?: Array<number>;
  /**
   * Define image sizes corresponding to device sizes
   */
  imageSizes?: Array<number>;
};

type State = {
  primaryImage: {
    src?: string;
  };
  secondImage: {
    src?: string;
  };
};

const getLoadingStrategy = (rootElementRef: MutableRefObject<HTMLDivElement | null>) => {
  const top = rootElementRef.current?.getBoundingClientRect().top;

  // Avoid lazy load above the fold images
  if (top && top > window.innerHeight) {
    return 'lazy';
  } else {
    return 'eager';
  }
};

const getSizes = (rootElementRef: MutableRefObject<HTMLDivElement | null>) => {
  const width = rootElementRef.current?.getBoundingClientRect().width || 200;
  return `${width}px`;
};

const getSrcSet = (src: string, deviceSizes: Array<number>, imageSizes?: Array<number>) => {
  let srcset = '';
  const regex =
    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/gi;

  if (!src || !src.match(regex)) return srcset;

  const url = new URL(src);

  deviceSizes.forEach((deviceSize, i) => {
    const size = imageSizes?.[i] || deviceSize;

    url.searchParams.set('width', size.toString());

    srcset += `${url.toString()} ${deviceSize}w`;

    if (i < deviceSizes.length - 1) {
      srcset += ', ';
    }
  });

  return srcset;
};

const ProductImage = (props: ProductImageProps) => {
  const {
    images,
    aspectRatio = 'aspect-ratio-3-4',
    manualAspectRatio,
    autoAspectRatio,
    naturalAspectRatio,
    altImage,
    onClick,
    isImageSlider,
    hoverEffect,
    elements,
    grid,
    cropPosition,
    imageModifiers,
    productId,
    originalSize,
    displayBlock,
    loadedImageSizeStrategy = 'fit-container-with-dpi',
    isShowWishlist,
    deviceSizes = [200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1600],
    imageSizes,
  } = props;

  const arrayImage = castArray<string>(images);
  const secondImgSrc = arrayImage[1];
  const dpr = useDevicePixelRatio();

  const rootElementRef = useRef<HTMLDivElement | null>(null);

  const [state, setState] = useState<State>({
    primaryImage: {
      src: undefined,
    },
    secondImage: {
      src: undefined,
    },
  });

  const showSecondImgOnHover =
    hoverEffect === 'reveal-second-image' || hoverEffect === 'reveal-second-image-and-zoom-in';

  const validatedAltImage = useMemo(() => stripHtml(altImage || ''), [altImage]);

  const renderElements = useCallback(() => {
    if (!elements) return null;

    return ['top', 'middle', 'bottom'].map((row) => {
      const rowData = grid?.[row as keyof ProductImageGrid];

      return (
        <div
          key={row}
          className={mapModifiers(clsNameMap.row, {
            top: row === 'top',
            middle: row === 'middle',
            bottom: row === 'bottom',
            vertical: rowData?.direction === 'vertical',
          })}
        >
          {['left', 'center', 'right'].map((column) => {
            const columnEls = rowData?.elements?.[column as keyof ProductImageGridRow['elements']];

            return (
              <div
                key={column}
                className={mapModifiers(clsNameMap.column, {
                  'in-top': row === 'top',
                  'in-middle': row === 'middle',
                  'in-bottom': row === 'bottom',
                  left: column === 'left',
                  center: column === 'center',
                  right: column === 'right',
                })}
              >
                {columnEls?.map((elementType) => {
                  return elements[elementType];
                })}
              </div>
            );
          })}
        </div>
      );
    });
  }, [elements, grid]);

  const styleRootElement = () => {
    switch (aspectRatio) {
      case 'auto':
        return { aspectRatio: autoAspectRatio };
      case 'natural':
        return { aspectRatio: naturalAspectRatio };
      case 'manual':
        return { aspectRatio: manualAspectRatio };
    }
  };

  useEffect(
    function lazyLoadImgSources() {
      let primaryImgSrc = arrayImage[0];
      let secondImgSrc = arrayImage[1];

      if (
        loadedImageSizeStrategy === 'fit-container' ||
        loadedImageSizeStrategy === 'fit-container-with-dpi'
      ) {
        const rootElement = rootElementRef.current;
        const originalPrimaryWidth = originalSize?.[0]?.width;

        const clientWidth = rootElement?.clientWidth;

        if (originalPrimaryWidth && clientWidth && originalPrimaryWidth > clientWidth) {
          const url = new URL(arrayImage[0]);
          let loadImageWidth = clientWidth;
          if (loadedImageSizeStrategy === 'fit-container-with-dpi') {
            loadImageWidth *= dpr;
          }

          url.searchParams.set('width', `${loadImageWidth}`);
          primaryImgSrc = url.toString();
        }

        const originalSecondWidth = originalSize?.[1]?.width;

        if (
          secondImgSrc &&
          originalSecondWidth &&
          clientWidth &&
          originalSecondWidth > clientWidth
        ) {
          const url = new URL(arrayImage[1]);
          let loadImageWidth = clientWidth;
          if (loadedImageSizeStrategy === 'fit-container-with-dpi') {
            loadImageWidth *= dpr;
          }

          url.searchParams.set('width', `${loadImageWidth}`);
          secondImgSrc = url.toString();
        }
      }

      setState((prevState) => ({
        ...prevState,
        primaryImage: {
          ...prevState.primaryImage,
          src: primaryImgSrc,
        },
        secondImage: {
          ...prevState.secondImage,
          src: secondImgSrc,
        },
      }));
    },
    [arrayImage[0], originalSize?.[0]?.width, dpr, loadedImageSizeStrategy]
  );

  return (
    <div
      className={mergeModifiers(clsNameMap.wrapper, [
        {
          'has-second-image': showSecondImgOnHover && !!secondImgSrc,
          'zoom-in': hoverEffect === 'zoom-in' || hoverEffect === 'reveal-second-image-and-zoom-in',
          'crop-top': cropPosition === 'top',
          'crop-center': cropPosition === 'center',
          'crop-bottom': cropPosition === 'bottom',
        },
        aspectRatio && [aspectRatio],
      ])}
      style={styleRootElement()}
      onClick={onClick}
      ref={composeRef(rootElementRef)}
    >
      <div className={mapModifiers(clsNameMap, imageModifiers)}>
        {state.primaryImage.src && (
          <>
            <Image
              id={`product-image-${productId}`}
              className={mapModifiers(clsNameMap.img, {
                main: true,
                'display-block': displayBlock,
                'as-slider': isImageSlider === true,
              })}
              src={state.primaryImage.src}
              alt={validatedAltImage}
              loading={getLoadingStrategy(rootElementRef)}
              sizes={getSizes(rootElementRef)}
              srcSet={getSrcSet(state.primaryImage.src, deviceSizes, imageSizes)}
            />
            {showSecondImgOnHover && !!state.secondImage.src && (
              <Image
                className={mapModifiers(clsNameMap.img, { second: true })}
                src={state.secondImage.src}
                alt={validatedAltImage}
                loading={getLoadingStrategy(rootElementRef)}
                sizes={getSizes(rootElementRef)}
                srcSet={getSrcSet(state.secondImage.src, deviceSizes, imageSizes)}
              />
            )}
          </>
        )}
      </div>
      {isShowWishlist && <IntegrationProductWishlist />}
      {renderElements()}
    </div>
  );
};

export default registryComponent('ProductImage', ProductImage);
