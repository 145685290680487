import { CurrencyProvider } from '@providers/CurrencyProvider';
import type { PropsWithChildren } from 'react';

import { CartStateProvider } from './CartState';

export type CartProviderProps = {
  cartIconHeaderSelector?: string;
  onUpdateCart?: (cartItemsCount: number, totalPrice?: number, currency?: string) => void;
};

export const CartProvider = (props: PropsWithChildren<CartProviderProps>) => {
  return (
    <CurrencyProvider>
      <CartStateProvider {...props} />
    </CurrencyProvider>
  );
};

export * from './CartState';
