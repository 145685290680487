import './Button.scss';

import { InnerHTMLRenderer } from '@components/InnerHTMLRenderer';
import type { ModifiersType } from '@utils';
import { appendClassNames, createClsNameMap, mergeModifiers } from '@utils';

export const clsNameMap = createClsNameMap({
  elements: {
    icon: createClsNameMap({
      modifiers: ['fill-current-color', 'on-right', 'is-image'],
    }),
    text: createClsNameMap(),
  },
  modifiers: [
    'circle',
    'square',
    'round',
    'primary',
    'secondary',
    'full-width',
    'as-quantity',
    'as-remove-item-side-cart',
    'as-search',
    'no-padding',
    'as-in-collection-search',
    'as-remove-item-popup-cart',
    'result',
    'clear',
    'clear-collection',
    'btn-filter-option-color',
    'apply',
    'hidden',
    'visible',
    'back',
    'close',
    'border',
    'left',
    'center',
    'right',
    'height-fit-content',
    'scroll-to-top',
    'scroll-to-top-style1',
    'scroll-to-top-style2',
    'large',
    'half-width',
    'truncate',
    'show-on-hover',
    'hide-on-mobile',
    'no-minwidth',
  ],
})('button');

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  icon?: string;
  iconImageSrc?: string;
  iconModifiers?: ModifiersType<(typeof clsNameMap)['icon'], true>;
  buttonModifiers?: ModifiersType<typeof clsNameMap, true>;
};

const Button = ({
  children,
  icon,
  buttonModifiers,
  iconModifiers,
  iconImageSrc,
  className,
  style,
  ...otherProps
}: ButtonProps) => {
  const buttonTextStyle = {
    fontSize: style?.fontSize,
    fontFamily: style?.fontFamily,
  };

  return (
    <button
      type='button'
      style={style}
      {...otherProps}
      className={appendClassNames(mergeModifiers(clsNameMap, [buttonModifiers]), className)}
    >
      {(icon || iconImageSrc) && (
        <InnerHTMLRenderer
          className={mergeModifiers(clsNameMap.icon, [
            {
              'is-image': !!iconImageSrc,
            },
            iconModifiers,
          ])}
          html={icon || ''}
          style={{
            backgroundImage: iconImageSrc ? `url('${iconImageSrc}')` : undefined,
          }}
        />
      )}
      {children && (
        <div style={buttonTextStyle} className={clsNameMap.elm('text')}>
          {children}
        </div>
      )}
    </button>
  );
};

export default Button;
