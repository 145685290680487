import './SliderPrevButton.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import Button from '@components/Button';
import useTranslation from '@hooks/useTranslation';
import { createClsNameMap, mapModifiers } from '@utils';
import type { ReactNode } from 'react';

const clsNameMap = createClsNameMap({
  elements: {
    customEl: createClsNameMap({
      modifiers: ['disabled'],
    }),
  },
  modifiers: ['outside', 'inside'],
})('prev-button');

const DefaultArrowSliderIcon = `
<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.764024 5.51189L5.00002 1.28789L9.23602 5.51189" stroke="#212121" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

export type SliderPrevButtonProps = {
  customEl?: ReactNode;
  disabled?: boolean;
  position?: 'inside' | 'outside';
  onChange?: () => void;
  style?: React.CSSProperties;
};

const SliderPrevButton = ({
  customEl,
  disabled,
  position = 'inside',
  onChange,
  style,
}: SliderPrevButtonProps) => {
  const { t } = useTranslation();

  return (
    <div style={style} className={mapModifiers(clsNameMap, [position])}>
      {customEl ? (
        <div
          className={mapModifiers(clsNameMap.customEl, { disabled: disabled })}
          onClick={onChange}
        >
          {customEl}
        </div>
      ) : (
        <Button
          aria-label={t('sliderProduct.prevButton')}
          icon={DefaultArrowSliderIcon}
          onClick={onChange}
          disabled={disabled}
          buttonModifiers={['circle']}
        />
      )}
    </div>
  );
};

export default registryComponent('SliderPrevButton', SliderPrevButton);
