import type { Dict, FunctionMembers } from '@boost-sd/core-js';
import { isAsyncFunction, isPromise } from '@boost-sd/core-js';

import type { ModuleMethodEventPayload, ModuleMethodEvents, ModulePlugin } from './plugin';

export type ModuleModel<P extends Dict> = {
  name: string;
  properties: P;
  plugins: Array<ModulePlugin<P>>;
  useOriginal?: boolean;
  __original: P;
};

export const createModuleModel = <P extends Dict>(name: string, moduleProps: P) => {
  const moduleModel: ModuleModel<P> = {
    name,
    plugins: [],
    __original: moduleProps,
    properties: new Proxy(moduleProps, {
      get(target, prop) {
        if (typeof target[prop] === 'function') {
          if (moduleModel.useOriginal) {
            return Reflect.get(target, prop);
          }

          const propMethod = prop as FunctionMembers<P>;

          const handlersByEvent = moduleModel.plugins.reduce(
            (r, plugin) => {
              const pluginMethodHooks = plugin.hooks.methods[propMethod] || {};

              r.beforeMethodCall.push(...(pluginMethodHooks.beforeMethodCall || []));

              r.methodPending.push(...(pluginMethodHooks.methodPending || []));

              r.methodFulfilled.push(...(pluginMethodHooks.methodFulfilled || []));

              r.methodReject.push(...(pluginMethodHooks.methodReject || []));

              return r;
            },
            {
              beforeMethodCall: [],
              methodPending: [],
              methodFulfilled: [],
              methodReject: [],
            } as {
              [event in ModuleMethodEvents]: Array<
                (
                  payload: ModuleMethodEventPayload<
                    Parameters<P[typeof propMethod]>,
                    ReturnType<P[typeof propMethod]>,
                    Error
                  >
                ) => void
              >;
            }
          );

          return new Proxy(target[propMethod], {
            apply: (method, thisArg, argumentsList: Parameters<P[typeof propMethod]>) => {
              const payload: ModuleMethodEventPayload<
                Parameters<P[typeof propMethod]>,
                ReturnType<typeof method>,
                Error
              > = {
                args: argumentsList,
                result: null,
                error: null,
              };

              if (isAsyncFunction(method)) {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve, reject) => {
                  await Promise.all(
                    handlersByEvent.beforeMethodCall.map((handler) => {
                      return handler(payload);
                    })
                  );

                  const result = Reflect.apply(method, thisArg, argumentsList);

                  if (isPromise(result)) {
                    payload.result = null;
                    payload.error = null;

                    await Promise.all(
                      handlersByEvent.methodPending.map((handler) => {
                        return handler(payload);
                      })
                    );

                    return result
                      .then(async (promiseResult) => {
                        payload.result = promiseResult;
                        payload.error = null;

                        await Promise.all(
                          handlersByEvent.methodFulfilled.map((handler) => {
                            return handler(payload);
                          })
                        );

                        if (payload.error != null) {
                          return reject(
                            (
                              payload as ModuleMethodEventPayload<
                                Parameters<P[typeof propMethod]>,
                                ReturnType<typeof method>,
                                Error
                              >
                            ).error
                          );
                        }

                        resolve(payload.result);
                      })
                      .catch(async (error) => {
                        payload.error = error;
                        payload.result = null;

                        await Promise.all(
                          handlersByEvent.methodReject.map((handler) => {
                            return handler(payload);
                          })
                        );

                        if (payload.result != null) {
                          return resolve(payload.result);
                        }

                        reject(payload.error);
                      });
                  }
                });
              }

              handlersByEvent.beforeMethodCall.map((handler) => {
                return handler(payload);
              });

              const result = Reflect.apply(method, thisArg, argumentsList);
              payload.result = result as ReturnType<typeof method>;
              payload.error = null;

              handlersByEvent.methodFulfilled.map((handler) => {
                return handler(payload);
              });

              return payload.result;
            },
          });
        } else {
          return Reflect.get(target, prop);
        }
      },
    }),
  };

  return moduleModel;
};
