import { registryModule } from '@boost-sd/components-registry/registry';
import type { Dict } from '@types';
import { createInstance } from 'i18next';
import { get, mergeWith, set } from 'lodash-es';
import { initReactI18next } from 'react-i18next';

import englishTranslationDefault from '../../locales/en/translation.json';

const i18nInstance = get(window, 'BoostSDI18next') || createInstance();

set(window, 'BoostSDI18next', i18nInstance);

export const bootstrapAppTranslation = () => {
  const customizer = (objValue: any, srcValue: any) => {
    if ((srcValue == null || srcValue === '') && objValue) {
      return objValue;
    }
  };

  const translation = mergeWith(
    {},
    window.boostSDAppConfig?.primary_language,
    window.boostSDAppConfig?.translation,
    customizer
  );

  const currentLocale = window.boostSDAppConfig?.generalSettings?.current_locale?.toLowerCase();

  // resources: default language
  const resources: Dict = {
    en: {
      translation: englishTranslationDefault,
    },
  };

  if (currentLocale && translation) {
    resources[currentLocale] = mergeWith(
      {},
      resources.en,
      {
        translation: translation,
      },
      customizer
    );
  }

  const currentLanguage = resources[currentLocale] ? currentLocale : 'en';

  i18nInstance
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: resources,
      lng: currentLanguage,
      fallbackLng: resources.en,
      lowerCaseLng: true,
      interpolation: {
        escapeValue: false,
      },
    });
};

export const translationModule = registryModule('Translation', {
  reloadCurrentLanguage() {
    const translation = window.boostSDAppConfig?.translation;
    const currentLocale = window.boostSDAppConfig?.generalSettings?.current_locale?.toLowerCase();

    i18nInstance.addResourceBundle(currentLocale, 'translation', translation, true, true);
    i18nInstance.changeLanguage(currentLocale);
  },
});
