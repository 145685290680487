import type { QuickViewButtonProps } from '@components/QuickViewButton';
import { useQuickViewThemeSettings } from '@providers/ThemeProvider/Provider/QuickViewThemeSettings';
import { useMemo } from 'react';

export type ButtonPropsMapSettings = QuickViewButtonProps;

export const useConnectQuickViewButton = (type: 'addToCart' | 'buyItNow') => {
  const quickViewSettings = useQuickViewThemeSettings();
  const buttonSettings =
    type === 'addToCart' ? quickViewSettings.addToCartBtn : quickViewSettings.buyItNowBtn;

  const props: ButtonPropsMapSettings = useMemo(() => {
    return {
      ...quickViewSettings.buttonOverall,
      textColor: buttonSettings?.color,
      textColorOnHover: buttonSettings?.hoverColor,
      backgroundColor: buttonSettings?.backgroundColor,
      backgroundColorOnHover: buttonSettings?.hoverBackgroundColor,
      borderColor: buttonSettings?.borderColor,
      borderColorOnHover: buttonSettings?.hoverBorderColor,
      textTransform: buttonSettings?.textTransform,
    };
  }, [buttonSettings, quickViewSettings]);

  return {
    props,
  };
};
