/* eslint-disable no-console */
export type LoggerConstructorParams = {
  enabled?: boolean;
  namespace?: string;
};

export class Logger {
  protected _enabled: boolean;

  constructor(params?: LoggerConstructorParams) {
    this._enabled = params?.enabled || true;

    const namespace = params?.namespace || 'default';
    LoggerNS[namespace] = this;
  }

  get enabled() {
    return this._enabled;
  }

  protected _guard = () => {
    return this._enabled;
  };

  enable(enabled = true) {
    this._enabled = enabled;
  }

  log = (...data: any[]) => {
    if (this._guard()) {
      console.log(...data);
    }
  };

  logLevel = (level: 'info' | 'warn' | 'error' = 'info', ...data: any[]) => {
    if (this._guard()) {
      console[level](...data);
    }
  };
}

const LoggerNS: Record<string, Logger> = {};

export const getLoggerNS = (namespace = 'default') => {
  const loggerNS = LoggerNS[namespace];

  if (!loggerNS) {
    console.warn("Logger hasn't been initialized");
    return;
  }

  return loggerNS;
};

export const logger: Logger = new Proxy({} as Logger, {
  get(_, p) {
    const loggerDefaultNS = getLoggerNS();
    if (!loggerDefaultNS) return;

    return Reflect.get(loggerDefaultNS, p);
  },
});
