import { CartProvider, ThemeProvider } from '@boost-sd/app';
import { createRoot } from 'react-dom/client';

export const initCart = () => {
  const cartContainerId = 'boost-sd__cart-container';
  const cartContainer = document.createElement('div');
  cartContainer.setAttribute('id', cartContainerId);
  document.body.appendChild(cartContainer);

  const root = createRoot(cartContainer);

  const updateCartIconActionForThemes = {
    blockShop: {
      selector: '.header--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.cart--external--icon');
        const cartCount = document.querySelector('.cart--external--total-items');

        if (!cartIconHeaderWrapper || !cartCount) return;

        cartIconHeaderWrapper.setAttribute('data-item-count', cartItemCount.toString());

        cartCount.innerHTML = cartItemCount.toString();
      },
    },
    colorBlock: {
      selector: '.header__icon--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-count-bubble')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('div');
        cartCountEl.className = 'cart-count-bubble';
        cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    craft: {
      selector: '.header__icon--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-count-bubble')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('div');
        cartCountEl.className = 'cart-count-bubble';
        cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    crave: {
      selector: '.header__icon--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-count-bubble')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('div');
        cartCountEl.className = 'cart-count-bubble';
        cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    dawn: {
      selector: '.header__icon--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-count-bubble')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('div');
        cartCountEl.className = 'cart-count-bubble';
        cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    debut: {
      selector: '.site-header__cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.site-header__cart');

        if (!cartIconHeaderWrapper) return;

        document.getElementById('CartCount')?.remove();

        const cartCountEl = document.createElement('div');
        cartCountEl.id = 'CartCount';
        cartCountEl.className = `site-header__cart-count ${
          cartItemCount > 0 ? '' : 'hide'
        } critical-hidden`;
        cartCountEl.innerHTML = `<span data-cart-count="">${cartItemCount}</span>
   <span class="icon__fallback-text medium-up--hide">items</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    default: {
      selector: '.header__icon--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-count-bubble')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('div');
        cartCountEl.className = 'cart-count-bubble';
        cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    ella: {
      selector: '.cart-icon a',
      action: (cartItemCount: number) => {
        const cartCountEls = document.querySelectorAll('.cartCount');

        cartCountEls.forEach((item) => (item.innerHTML = cartItemCount.toString()));
      },
    },
    empire: {
      selector: '.site-header-cart--button',
      action: (cartItemCount: number) => {
        const cartCountEl = document.querySelector('.site-header-cart--count');

        if (!cartCountEl) return;

        cartCountEl.className = `site-header-cart--count ${cartItemCount > 0 ? 'visible' : ''}`;
        cartCountEl.setAttribute('data-header-cart-count', `${cartItemCount || ''}`);
      },
    },
    flex: {
      selector: '.header-cart .header__link',
      action: (cartItemCount: number) => {
        const cartCountEls = document.querySelectorAll('.header-cart__count');
        const cartIconEls = document.querySelectorAll('.header-cart');

        cartIconEls.forEach(
          (item) =>
            (item.className = `header-cart action-area__link ${
              cartItemCount > 0 ? 'has-cart-count' : ''
            }`)
        );
        cartCountEls.forEach((item) => (item.innerHTML = cartItemCount.toString()));
      },
    },
    flow: {
      selector: '.site-header__cart-toggle',
      action: (cartItemCount: number) => {
        const cartCountEl = document.querySelector('.cart-item-count-header');

        if (!cartCountEl) return;

        const cartCountClassName = `cart-item-count-header cart-item-count-header--has-label cart-item-count-header--quantity ${
          cartItemCount > 0 ? '' : 'hide'
        }`;

        cartCountEl.className = cartCountClassName;
        cartCountEl.innerHTML = cartItemCount.toString();
      },
    },
    icon: {
      selector: '.slideout__trigger--open a',
      action: (cartItemCount: number) => {
        const cartCountEls = document.querySelectorAll('.js-cart-count');

        cartCountEls.forEach((item) => (item.innerHTML = cartItemCount.toString()));
      },
    },
    impulse: {
      selector: '.js-drawer-open-cart',
      action: (cartItemCount: number) => {
        const cartCountEl = document.querySelector('.cart-link__bubble');

        if (!cartCountEl) return;

        const cartCountClassName = `cart-link__bubble ${
          cartItemCount > 0 ? 'cart-link__bubble--visible' : ''
        }`;

        cartCountEl.className = cartCountClassName;
      },
    },
    motion: {
      selector: '.js-drawer-open-cart',
      action: (cartItemCount: number) => {
        const cartCountEl = document.querySelector('.cart-link__bubble');

        if (!cartCountEl) return;

        const cartCountClassName = `cart-link__bubble ${
          cartItemCount > 0 ? 'cart-link__bubble--visible' : ''
        }`;

        cartCountEl.className = cartCountClassName;
      },
    },
    prestige: {
      selector: '.Header__Wrapper [href*="cart"]',
      action: (cartItemCount: number) => {
        const cartDotEl = document.querySelector('.Header__CartDot');

        if (cartDotEl) {
          const cartCountClassName = `Header__CartDot ${cartItemCount > 0 ? 'is-visible' : ''}`;

          cartDotEl.className = cartCountClassName;
        }

        const cartCountEl = document.querySelector('.Header__CartCount');

        if (cartCountEl) {
          cartCountEl.innerHTML = cartItemCount.toString();
        }
      },
    },
    refresh: {
      selector: '.header__icon--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-count-bubble')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('div');
        cartCountEl.className = 'cart-count-bubble';
        cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    ride: {
      selector: '.header__icon--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-count-bubble')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('div');
        cartCountEl.className = 'cart-count-bubble';
        cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    sense: {
      selector: '.header__icon--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-count-bubble')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('div');
        cartCountEl.className = 'cart-count-bubble';
        cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    studio: {
      selector: '.header__icon--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-count-bubble')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('div');
        cartCountEl.className = 'cart-count-bubble';
        cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    superstore: {
      selector: '.site-header-cart-button',
      action: (cartItemCount: number) => {
        const cartCountEl = document.querySelector('.site-header-cart--count');

        if (!cartCountEl) return;

        cartCountEl.className = `site-header-cart--count ${cartItemCount > 0 ? 'visible' : ''}`;
        cartCountEl.setAttribute('data-header-cart-count', `${cartItemCount || ''}`);
      },
    },
    symetry: {
      selector: 'a.cart-link',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.cart-link__icon');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-link__count')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('span');
        cartCountEl.className = 'cart-link__count';
        cartCountEl.innerHTML = cartItemCount.toString();

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    taste: {
      selector: '.header__icon--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-count-bubble')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('div');
        cartCountEl.className = 'cart-count-bubble';
        cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    testament: {
      selector: '.js-mini-cart-trigger',
      action: (cartItemCount: number) => {
        const cartCountEls = document.querySelectorAll('.js-cart-count');

        cartCountEls.forEach((item) => (item.innerHTML = cartItemCount.toString()));
      },
    },
    turbo: {
      selector: '.header .icon-cart',
      action: (cartItemCount: number) => {
        const cartCountEls = document.querySelectorAll('.cart_count');

        cartCountEls.forEach((item) => (item.innerHTML = cartItemCount.toString()));
      },
    },
    venture: {
      selector: '.site-header__cart',
      action: (cartItemCount: number) => {
        const cartCountEl = document.querySelector('.site-header__cart-indicator');

        if (!cartCountEl) return;

        const cartCountClassName = `site-header__cart-indicator ${cartItemCount > 0 ? '' : 'hide'}`;

        cartCountEl.className = cartCountClassName;
      },
    },
    venue: {
      selector: '.header-trigger__link--cart',
      action: (cartItemCount: number) => {
        const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

        if (!cartIconHeaderWrapper) return;

        document.querySelector('.cart-count-bubble')?.remove();

        if (cartItemCount === 0) return;

        const cartCountEl = document.createElement('div');
        cartCountEl.className = 'cart-count-bubble';
        cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

        cartIconHeaderWrapper.append(cartCountEl);
      },
    },
    warehouse: {
      selector: '.header__cart-toggle',
      action: (cartItemCount: number) => {
        const cartCountEl = document.querySelector('.header__cart-count');

        if (!cartCountEl) return;

        cartCountEl.innerHTML = cartItemCount.toString();
      },
    },
    expanse: {
      selector: '#HeaderCartTrigger',
      action: (cartItemCount: number) => {
        const cartBubbleEl = document.querySelector('.cart-link__bubble');
        const cartCountEl = document.querySelector('.cart-link__bubble-num');

        if (!cartBubbleEl || !cartCountEl) return;

        const cartBubbleClassName = `cart-link__bubble ${
          cartItemCount > 0 ? 'cart-link__bubble--visible' : ''
        }`;
        cartBubbleEl.className = cartBubbleClassName;

        cartCountEl.innerHTML = cartItemCount.toString();
      },
    },
  };

  const cartSelectorAndUpdateAction = Object.values(updateCartIconActionForThemes).find((item) =>
    document.querySelector(item.selector)
  );

  root.render(
    <ThemeProvider>
      <CartProvider
        cartIconHeaderSelector={cartSelectorAndUpdateAction?.selector}
        onUpdateCart={cartSelectorAndUpdateAction?.action}
      />
    </ThemeProvider>
  );
};
