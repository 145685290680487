import './CartItem.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import Button from '@components/Button';
import { InnerHTMLRenderer } from '@components/InnerHTMLRenderer';
import ProductImage from '@components/ProductImage';
import ProductLink from '@components/ProductLink';
import Quantity from '@components/Quantity';
import useGeneralSettings from '@hooks/useGeneralSettings';
import useTranslation from '@hooks/useTranslation';
import type { CartItemData, OptionItem } from '@providers/CartProvider';
import { useCurrency } from '@providers/CurrencyProvider';
import {
  buildProductDetailUrlWithVariant,
  createClsNameMap,
  formatMoney,
  mapModifiers,
  setWindowLocation,
} from '@utils';

export const clsNameMap = createClsNameMap({
  elements: {
    info: createClsNameMap({
      modifiers: ['wider-width'],
    }),
    image: createClsNameMap(),
    content: createClsNameMap({
      modifiers: ['wider-width'],
    }),
    price: createClsNameMap(),
    'price-total': createClsNameMap(),
    title: createClsNameMap({
      modifiers: ['non-space'],
    }),
    option: createClsNameMap({
      elements: {
        title: createClsNameMap(),
        value: createClsNameMap(),
      },
    }),
    quantity: createClsNameMap(),
    'quantity-price': createClsNameMap(),
    'quantity-character': createClsNameMap(),
    'remove-btn-wrapper': createClsNameMap(),
  },
})('cart-item');

const IconRemove = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.84 8.8H10.8V16.48H9.84V8.8ZM11.76 8.8H12.72V16.48H11.76V8.8ZM13.68 8.8H14.64V16.48H13.68V8.8ZM6 5.92H18.48V6.88H6V5.92ZM14.64 5.92H13.68V5.44C13.68 5.152 13.488 4.96 13.2 4.96H11.28C10.992 4.96 10.8 5.152 10.8 5.44V5.92H9.84V5.44C9.84 4.624 10.464 4 11.28 4H13.2C14.016 4 14.64 4.624 14.64 5.44V5.92Z" fill="#31373D"/>
<path d="M15.12 19.3601H9.35996C8.59196 19.3601 7.91996 18.7361 7.82396 17.9681L6.95996 6.44805L7.91996 6.35205L8.78396 17.8721C8.78396 18.1601 9.07196 18.4001 9.35996 18.4001H15.12C15.408 18.4001 15.648 18.1601 15.696 17.8721L16.56 6.35205L17.52 6.44805L16.656 17.9681C16.56 18.7361 15.888 19.3601 15.12 19.3601Z" fill="#31373D"/>
</svg>`;

export type CartItemProps = {
  data: CartItemData;
  currency: string;
  isInPopup?: boolean;
  handleRemoveCartItem?: (key: string) => void;
  handleChangeQuantityCartItem?: (key: string, quantity: number) => void;
};

const CartItem = ({
  data,
  currency,
  isInPopup,
  handleRemoveCartItem,
  handleChangeQuantityCartItem,
}: CartItemProps) => {
  const { t } = useTranslation();
  const { product_title, final_price, quantity, options_with_values, key, line_price, image } =
    data;
  const {
    generalSettings: { no_image_url, current_tags, addCollectionToProductUrl: hasCollection },
  } = useGeneralSettings();
  const { rateCurrency, unitCurrency } = useCurrency();

  const imgSrc = image && image.length > 0 ? image : no_image_url;

  const onClickProductImage = (e: React.MouseEvent) => {
    setWindowLocation(
      buildProductDetailUrlWithVariant(data, hasCollection, current_tags || undefined)
    );
  };

  const onClickRemoveButton = (e: React.MouseEvent) => {
    if (handleRemoveCartItem) handleRemoveCartItem(key);
  };

  const onChangeQuantity = (value: number | '') => {
    if (handleChangeQuantityCartItem) handleChangeQuantityCartItem(key, value === '' ? 1 : value);
  };

  const generalTotal = (price: string) => {
    const totalAmount = formatMoney((Number(price) / 100) * rateCurrency, unitCurrency);
    return `${totalAmount}`;
  };

  const checkDefaultOption = (item: OptionItem) => {
    /** If no have any option BE always return default option name title. This filter to hide default option */
    return !(item.name === 'Title' && item.value === 'Default Title');
  };

  return (
    <div className={mapModifiers(clsNameMap, { popup: isInPopup })}>
      <div className={clsNameMap.elm('image')} onClick={onClickProductImage}>
        <ProductLink data={data}>
          <ProductImage images={imgSrc} aspectRatio='aspect-ratio-3-4' altImage={product_title} />
        </ProductLink>
      </div>
      <div
        className={mapModifiers(clsNameMap.content, {
          'wider-width': isInPopup,
        })}
      >
        <div
          className={mapModifiers(clsNameMap.info, {
            'wider-width': isInPopup,
          })}
        >
          <ProductLink data={data}>
            <div
              className={mapModifiers(clsNameMap.title, {
                'non-space': isInPopup,
              })}
            >
              {product_title}
            </div>
          </ProductLink>
          {options_with_values
            .filter((item) => checkDefaultOption(item))
            .map((item, index: number) => (
              <div className={clsNameMap.elm('option')} key={index}>
                <span className={clsNameMap.elm('option.title')}>{item.name}: </span>
                <span className={clsNameMap.elm('option.value')}>{item.value}</span>
              </div>
            ))}
          {!isInPopup && (
            <div className={clsNameMap.elm('quantity')}>
              <Quantity
                isCheckSideCart={true}
                value={quantity}
                onChange={onChangeQuantity}
                modifier={['small']}
              />
              <div className={clsNameMap.elm('quantity-price')}>
                <span className={clsNameMap.elm('quantity-character')}>{'x'}</span>
                <InnerHTMLRenderer as='span' html={generalTotal(final_price || '0')} />
              </div>
            </div>
          )}
        </div>
        {isInPopup && (
          <div className={clsNameMap.elm('price')}>
            <InnerHTMLRenderer as='span' html={generalTotal(final_price || '0')} />
          </div>
        )}
        {isInPopup && (
          <Quantity value={quantity} onChange={onChangeQuantity} modifier={['medium']} />
        )}
        {isInPopup && (
          <div className={clsNameMap.elm('price-total')}>
            <InnerHTMLRenderer as='span' html={generalTotal(line_price)} />
          </div>
        )}
        <Button
          title={t('cart.atcMiniCartRemoveItemLabel')}
          icon={IconRemove}
          onClick={onClickRemoveButton}
          buttonModifiers={['as-remove-item-side-cart']}
        ></Button>
      </div>
    </div>
  );
};

export default registryComponent('CartItem', CartItem);
