// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPromise = (obj: any): obj is Promise<any> => {
  return (
    !!obj &&
    (typeof obj === 'object' || typeof obj === 'function') &&
    typeof obj.then === 'function'
  );
};

export default isPromise;
