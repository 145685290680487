import type { RegistryComponentProps } from '@boost-sd/components-registry';
import DOMPurify from 'dompurify';
import type { DetailsHTMLAttributes, ReactHTML } from 'react';
import { createElement } from 'react';

export type InnerHTMLRendererProps = RegistryComponentProps<
  {
    html: string;
    as?: keyof ReactHTML;
    /**
     * @default true
     */
    preventXSS?: boolean;
  } & DetailsHTMLAttributes<unknown>
>;

export const InnerHTMLRenderer = ({
  html,
  as = 'div',
  preventXSS = true,
  helpersRef: _,
  ...props
}: InnerHTMLRendererProps) => {
  return createElement(as, {
    ...props,
    dangerouslySetInnerHTML: {
      __html: preventXSS ? DOMPurify.sanitize(html) : html,
    },
  });
};
