import './ProductLink.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import useGeneralSettings from '@hooks/useGeneralSettings';
import type { Dict } from '@types';
import { appendClassNames, buildProductDetailUrlWithVariant, createClsNameMap } from '@utils';
import type { PropsWithChildren } from 'react';

export const clsNameMap = createClsNameMap()('product-link');

export type ProductLinkProps = PropsWithChildren<{
  data: Dict;
  disabled?: boolean;
  variantId?: string;
  className?: string;
}>;

const ProductLink = ({ data, children, disabled, variantId, className }: ProductLinkProps) => {
  const {
    generalSettings: { addCollectionToProductUrl: hasCollection },
  } = useGeneralSettings();

  if (disabled) return <>{children}</>;

  return (
    <a
      className={appendClassNames(clsNameMap.root(), className)}
      href={buildProductDetailUrlWithVariant(data, hasCollection, undefined, variantId)}
    >
      {children}
    </a>
  );
};

export default registryComponent('ProductLink', ProductLink);
