import type { RegistryComponentProps } from '@boost-sd/components-registry';
import { registryComponent } from '@boost-sd/components-registry/registry';
import type { DeepPartial } from '@types';
import { merge } from 'lodash-es';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useImperativeHandle, useState } from 'react';

import type { CartThemeSettings } from '../types';

const CartThemeSettingsContext = createContext<CartThemeSettings | null>(null);

export type CartThemeSettingsProviderProps = PropsWithChildren<{
  defaultSettings?: CartThemeSettings;
}>;

export const CartThemeSettingsProvider = registryComponent(
  'CartThemeSettingsProvider',
  ({
    children,
    defaultSettings,
    helpersRef,
  }: RegistryComponentProps<
    CartThemeSettingsProviderProps,
    {
      reloadSettings: (settings?: DeepPartial<CartThemeSettings>) => void;
      getSettings: () => CartThemeSettings;
    }
  >) => {
    const settingsFromWindow = window?.boostSDAppConfig?.themeSettings?.cart || {};
    const [settings, setSettings] = useState(merge({}, settingsFromWindow, defaultSettings));

    useImperativeHandle(helpersRef, () => ({
      getSettings() {
        return settings;
      },
      reloadSettings(settings) {
        const settingsFromWindow = window?.boostSDAppConfig?.themeSettings?.cart || {};
        setSettings(merge({}, settingsFromWindow, defaultSettings, settings));
      },
    }));

    return (
      <CartThemeSettingsContext.Provider value={settings}>
        {children}
      </CartThemeSettingsContext.Provider>
    );
  }
);

export const useCartThemeSettings = () => {
  const context = useContext(CartThemeSettingsContext);
  if (!context) {
    throw Error('Use useCartThemeSettings in CartThemeSettingsProvider');
  }

  return context;
};
