import type { Dict } from '@boost-sd/core-js';
import {
  isHTMLSupportRenderElement,
  LegacyDOMRepresentation,
  SlotDOMRender,
} from '@boost-sd/core-js';
import { castArray, get, set } from 'lodash-es';
import { cloneElement, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { flushSync } from 'react-dom';

import type { ModuleModel, ModulePluginBuilders, ModulePluginOptions } from './modules';
import { createModuleModel, createModulePlugin } from './modules';
import type {
  ComponentContext,
  ComponentModel,
  ComponentPlugin,
  ComponentPluginOptions,
  ElementModel,
  RenderContext,
  SupportedRenderElement,
} from './react-component';
import {
  applyComponentPlugin,
  ComponentRenderingContext,
  defaultComponentLogger,
  useElementModel,
} from './react-component';

export type IComponentRegistry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components: Record<string, ComponentModel<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modules: Record<string, ModuleModel<any>>;

  queueModulesPluginInit: {
    [name: string]: Array<() => void>;
  };

  getComponentOptionsInContext: <P>(
    name: string,
    context?: string | string[],
    options?: {
      exact?: boolean;
    }
  ) => ComponentModel<P> | ComponentContext<P> | undefined;

  getComponentPluginsInContext: <P>(
    name: string,
    contextPaths?: string[] | string,
    options?: {
      enabledOnly?: boolean;
    }
  ) => ComponentPlugin<P>[] | undefined;

  useComponentPlugin: <P>(
    componentWithContext: string,
    plugins: ComponentPluginOptions<P> | Array<ComponentPluginOptions<P>>
  ) => void;

  setComponentDebug: (componentWithContext: string, debug?: boolean) => void;

  getParentElmByPath: <E, P>(
    element: ElementModel<E>,
    paths: string[] | string
  ) => ElementModel<P> | undefined;

  getNearestParentElm: <E, P>(
    element: ElementModel<E>,
    name: string
  ) => ElementModel<P> | undefined;

  getChildElmByPath: <E, P>(element: ElementModel<E>, path: string) => ElementModel<P> | undefined;

  useModulePlugin: <P extends Dict>(
    name: string,
    plugins: ModulePluginOptions<P> | Array<ModulePluginOptions<P>>
  ) => void;

  getModule: <P extends Dict>(name: string, options?: { original?: boolean }) => P | undefined;
};

export const ComponentRegistrySymbol = 'BoostSDComponentRegistry';

const ComponentRegistry: IComponentRegistry = get(window, ComponentRegistrySymbol) || {
  components: {},
  modules: {},
  queueModulesPluginInit: {},
  getComponentOptionsInContext(name, context, options = { exact: true }) {
    const component = ComponentRegistry.components[name];

    if (!component) {
      console.warn(`Component ${name} not found`);
      return;
    }

    if (!context || context.length === 0) {
      return component;
    }

    const contextPath = Array.isArray(context) ? context : context.split('.');
    let componentContext = component.contexts[contextPath[0]];

    for (let index = 1; index < contextPath.length; index++) {
      const path = contextPath[index];
      if (!componentContext?.children[path]) {
        if (options.exact) return;
        return componentContext;
      }

      componentContext = componentContext.children[path];
    }

    return componentContext;
  },

  useComponentPlugin<P>(
    componentWithContext: string,
    plugins: ComponentPluginOptions<P> | Array<ComponentPluginOptions<P>>
  ) {
    castArray(plugins).forEach((plugin: ComponentPluginOptions<P>) => {
      const paths = componentWithContext.split('.');
      const componentName = paths.at(-1);

      if (!componentName) {
        return;
      }

      if (!ComponentRegistry.components[componentName]) {
        ComponentRegistry.components[componentName] = {
          name: componentName,
          plugins: [],
          contexts: {},
        };
      }

      const component = ComponentRegistry.components[componentName];
      const pluginMergedDefaultOpts: ComponentPlugin<P> = Object.assign(plugin, {
        options: plugin.apply(),
      });

      if (!('enabled' in pluginMergedDefaultOpts)) {
        pluginMergedDefaultOpts.enabled = true;
      }

      const subContextPaths = paths.slice(0, -1);
      if (subContextPaths.length === 0) {
        component.plugins.push(pluginMergedDefaultOpts);
        return;
      }

      let context = (component.contexts[subContextPaths[0]] ??= {
        plugins: [],
        children: {},
      });

      if (subContextPaths.length > 0) {
        for (let index = 1; index < subContextPaths.length; index++) {
          const path = subContextPaths[index];

          context.children[path] ??= { plugins: [], children: {} };
          context = context.children[path];
        }
      }

      context.plugins.push(pluginMergedDefaultOpts);
    });
  },

  getComponentPluginsInContext(name, contextPaths, options) {
    if (!name || !ComponentRegistry.components[name]) {
      return;
    }

    const component = ComponentRegistry.components[name];
    const plugins = [...component.plugins];

    if (!contextPaths || contextPaths.length === 0) return plugins;
    const paths = Array.isArray(contextPaths) ? contextPaths : contextPaths.split('.');

    let context = component.contexts;

    for (let index = 0; index < paths.length; index++) {
      const path = paths[index];
      if (!context[path]) continue;
      plugins.push(...context[path].plugins);
      context = context[path].children;
    }

    return plugins.filter((plugin) => {
      if (options?.enabledOnly === true) {
        return !!plugin.enabled;
      }

      return true;
    });
  },

  setComponentDebug(componentWithContext, debug = true) {
    const paths = componentWithContext.split('.');
    const componentName = paths.at(-1);
    if (!componentName) return;

    const context = paths.slice(0, -1);
    const componentOptions = ComponentRegistry.getComponentOptionsInContext(
      componentName,
      context,
      {
        exact: false,
      }
    );

    if (componentOptions) {
      componentOptions.debug = debug;
    }
  },

  getParentElmByPath<E, P>(element: ElementModel<E>, pathsOrString: string[] | string) {
    const paths = Array.isArray(pathsOrString) ? pathsOrString : pathsOrString.split('.');
    let parent: ElementModel<P> | undefined = element.getParentElm();
    if (!parent || parent.name !== paths.at(-1)) return;

    for (let index = paths.length - 2; index >= 0; index--) {
      parent = parent.getParentElm();
      const path = paths[index];
      if (parent?.name !== path) return;
    }

    return parent;
  },

  getNearestParentElm<E, P>(element: ElementModel<E>, name: string) {
    let parent: ElementModel<P> | undefined = element.getParentElm();

    while (parent?.name !== name) {
      if (!parent) return;

      parent = parent.getParentElm();
    }

    return parent;
  },

  getChildElmByPath<E, P>(element: ElementModel<E>, path: string) {
    const paths = path.split('.');
    let childElement = element as unknown as ElementModel<P>;

    for (let index = 0; index < paths.length; index++) {
      const childName = paths[index];
      const renderContext = childElement.getElmRenderContextValue();
      if (!renderContext) break;

      const childRenderContextByName = get(renderContext.childrenContext, childName);
      if (!childRenderContextByName || Array.isArray(childRenderContextByName)) return;

      const nextElement = childRenderContextByName()?.element;
      if (!nextElement) return;
      childElement = nextElement;
    }

    return childElement;
  },

  useModulePlugin<P extends Dict>(
    name: string,
    plugins: ModulePluginOptions<P> | Array<ModulePluginOptions<P>>
  ) {
    const moduleModel: ModuleModel<P> = ComponentRegistry.modules[name];
    if (!moduleModel) {
      if (!ComponentRegistry.queueModulesPluginInit[name]) {
        ComponentRegistry.queueModulesPluginInit[name] = [];
      }

      ComponentRegistry.queueModulesPluginInit[name].push(() => {
        ComponentRegistry.useModulePlugin(name, plugins);
      });

      return;
    }

    const moduleProperties = moduleModel.properties;

    castArray(plugins).forEach((pluginOptions) => {
      const plugin = createModulePlugin(pluginOptions, moduleProperties);
      const methodHooks = plugin.hooks.methods;

      const builder: ModulePluginBuilders<P> = {
        on(event, target, handler) {
          if (typeof moduleProperties[target] === 'function') {
            const methodEventHook = methodHooks[target][event];
            methodEventHook?.add(handler);

            return () => {
              methodEventHook?.delete(handler);
            };
          }
        },
      };

      pluginOptions.apply(builder);

      moduleModel.plugins.push(plugin);
    });
  },

  getModule(name, options) {
    const moduleModel = ComponentRegistry.modules[name];
    if (!moduleModel) {
      console.warn(`Module ${name} not found`);

      return;
    }

    if (options?.original) return moduleModel.__original;

    return moduleModel.properties;
  },
};

set(window, ComponentRegistrySymbol, ComponentRegistry);

const requestIdleCallback =
  window.requestIdleCallback ||
  function (cb: (payload: { didTimeout: boolean; timeRemaining: () => number }) => unknown) {
    const start = Date.now();
    return setTimeout(function () {
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };

export const registryComponent = <P,>(name: string, Component: React.ComponentType<P>) => {
  const lazyComponentConfig = ComponentRegistry.components[name];
  const componentModel: ComponentModel<P> = {
    name,
    plugins: lazyComponentConfig?.plugins || [],
    contexts: lazyComponentConfig?.contexts || {},
    CustomizedComponentHOC(props: P) {
      const renderingContext = useContext(ComponentRenderingContext);
      const renderingContextValueRef = useRef<RenderContext<P> | null>(null);

      // TODO: optimize - memoization
      const componentPlugins =
        ComponentRegistry.getComponentPluginsInContext<P>(
          name,
          renderingContext?.renderContextPath,
          { enabledOnly: true }
        ) || [];

      const componentOptions = ComponentRegistry.getComponentOptionsInContext(
        name,
        renderingContext?.renderContextPath,
        {
          exact: false,
        }
      );

      const hasEffectQueue = useRef(false);
      const hasLayoutEffectQueue = useRef(false);

      const {
        rootElementRef,
        element,
        updateState: updateElementState,
      } = useElementModel({
        name,
        renderState: {
          props,
        },
        plugins: componentPlugins,
        parent: renderingContext?.element,
        renderContextPaths: renderingContext?.renderContextPath && [
          ...renderingContext.renderContextPath,
        ],
        getElmRenderContextValue() {
          return renderingContextValueRef.current;
        },
      });

      let component: SupportedRenderElement = (
        <Component {...(element.getParams().props as P & JSX.IntrinsicClassAttributes<P>)} />
      );

      const logger = defaultComponentLogger({
        element,
        enabled: !!(componentModel.debug || componentOptions?.debug),
      });

      const enableCustomization = !!componentPlugins?.length;

      // TODO: optimize - prepare action by action on phase

      if (enableCustomization) {
        componentPlugins.forEach((plugin) => {
          logger.logAction(
            () => {
              const transformedData = applyComponentPlugin(plugin.options, 'transform', element);

              updateElementState(transformedData);
            },
            { onPhase: 'transform', source: plugin.name }
          );
        });

        if (element.isInitialing()) {
          componentPlugins.forEach((plugin) => {
            logger.logAction(
              () => {
                applyComponentPlugin(plugin.options, 'before-init', element);
              },
              { onPhase: 'before-init', source: plugin.name }
            );
          });
        }

        const props = element.getParams().props;

        component = cloneElement(component, props as P & JSX.IntrinsicClassAttributes<P>);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any

        componentPlugins.forEach((plugin) => {
          logger.logAction(
            () => {
              if (plugin.options.render) {
                component = applyComponentPlugin(plugin.options, 'render', element, component);
              }
            },
            { onPhase: 'render', source: plugin.name }
          );
        });
      }

      useEffect(function applyAfterInitPlugin() {
        componentPlugins?.forEach((plugin) => {
          logger.logAction(
            () => {
              applyComponentPlugin(plugin.options, 'after-init', element);
            },
            { onPhase: 'after-init', source: plugin.name }
          );
        });

        return () => {
          componentPlugins?.forEach((plugin) => {
            logger.logAction(
              () => {
                applyComponentPlugin(plugin.options, 'unmount', element);
              },
              {
                onPhase: 'unmount',
                source: plugin.name,
              }
            );
          });
        };
      }, []);

      useLayoutEffect(function applyLayoutEffectPlugin() {
        if (!hasLayoutEffectQueue.current) {
          hasLayoutEffectQueue.current = true;

          componentPlugins.forEach((plugin) => {
            logger.logAction(
              () => {
                applyComponentPlugin(plugin.options, 'before-render', element);
              },
              { onPhase: 'before-render', source: plugin.name }
            );
          });

          hasLayoutEffectQueue.current = false;
        }
      });

      useEffect(function applyAfterRenderPlugin() {
        if (!hasEffectQueue.current) {
          hasEffectQueue.current = true;

          componentPlugins?.forEach((plugin) => {
            logger.logAction(
              () => {
                applyComponentPlugin(plugin.options, 'after-render', element);
              },
              { onPhase: 'after-render', source: plugin.name }
            );
          });

          hasEffectQueue.current = false;
        }
      });

      useEffect(
        function applyProxyReactFiber() {
          const rootElement = element.getRootElm();
          if (rootElement) {
            const key = Object.keys(rootElement).find((key) => key.startsWith('__reactFiber$'));
            if (key) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const fiberInstance: any = rootElement[key as keyof typeof rootElement];
              if (fiberInstance.return?.alternate) {
                fiberInstance.return.alternate = new Proxy(fiberInstance.return.alternate, {
                  set(target, p, newValue) {
                    if (p === 'return') {
                      if (!hasLayoutEffectQueue.current) {
                        hasLayoutEffectQueue.current = true;

                        componentPlugins?.forEach((plugin) => {
                          logger.logAction(
                            () => {
                              applyComponentPlugin(plugin.options, 'before-render', element);
                            },
                            { onPhase: 'before-render', source: plugin.name }
                          );
                        });

                        hasLayoutEffectQueue.current = false;
                      }

                      if (!hasEffectQueue.current) {
                        hasEffectQueue.current = true;

                        requestIdleCallback(() => {
                          componentPlugins?.forEach((plugin) => {
                            logger.logAction(
                              () => {
                                applyComponentPlugin(plugin.options, 'after-render', element);
                              },
                              { onPhase: 'after-render', source: plugin.name }
                            );
                          });

                          hasEffectQueue.current = false;
                        });
                      }
                    }
                    return Reflect.set(target, p, newValue);
                  },
                });
              }
            }
          }
        },
        [element.getRootElm()]
      );

      const childrenContextRef = useRef<RenderContext<P>['childrenContext']>({});

      const renderingContextValue: RenderContext<P> = {
        element,
        renderContextPath: [...(renderingContext?.renderContextPath || []), name],
        childrenContext: childrenContextRef.current,
        registryChildrenContext<P>(name: string, context: () => RenderContext<P>) {
          const contextByName = childrenContextRef.current[name];
          if (!contextByName) {
            childrenContextRef.current[name] = context;
          }

          if (contextByName && !Array.isArray(contextByName)) {
            childrenContextRef.current[name] = [contextByName, context];
          }
        },
      };

      renderingContextValueRef.current = renderingContextValue;

      useEffect(() => {
        if (renderingContext) {
          renderingContext.registryChildrenContext(name, () => renderingContextValueRef.current);
        }
      }, []);

      if (!enableCustomization) {
        return (
          <ComponentRenderingContext.Provider value={renderingContextValue}>
            {component}
          </ComponentRenderingContext.Provider>
        );
      }

      return (
        <ComponentRenderingContext.Provider value={renderingContextValue}>
          <LegacyDOMRepresentation innerRef={rootElementRef}>
            {isHTMLSupportRenderElement(component) ? (
              <SlotDOMRender elements={component} />
            ) : (
              component
            )}
          </LegacyDOMRepresentation>
        </ComponentRenderingContext.Provider>
      );
    },
  };

  ComponentRegistry.components[name] = componentModel;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return componentModel.CustomizedComponentHOC!;
};

export const registryModule = <P extends Dict>(name: string, moduleProps: P) => {
  const moduleModel = createModuleModel(name, moduleProps);
  ComponentRegistry.modules[name] = moduleModel;

  if (ComponentRegistry.queueModulesPluginInit[name]?.length) {
    ComponentRegistry.queueModulesPluginInit[name].forEach((init) => init());
    ComponentRegistry.queueModulesPluginInit[name] = [];
  }

  return moduleModel.properties;
};

export const getComponentRegistry = (): IComponentRegistry => ComponentRegistry;
