import './Cart.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import CartButton from '@components/CartButton';
import CartItem from '@components/CartItem';
import type { ReactNodeRenderer } from '@components/CustomizableNode';
import CustomizableNode from '@components/CustomizableNode';
import { InnerHTMLRenderer } from '@components/InnerHTMLRenderer';
import Modal from '@components/Modal';
import useTranslation from '@hooks/useTranslation';
import type { CartData } from '@providers/CartProvider';
import { useCurrency } from '@providers/CurrencyProvider';
import { useCartThemeSettings } from '@providers/ThemeProvider/Provider/CartThemeSettings';
import type { CartStyle } from '@providers/ThemeProvider/types';
import {
  buildUrlWithLocalization,
  createClsNameMap,
  formatMoney,
  isMobileWidth,
  mapModifiers,
  setWindowLocation,
} from '@utils';
import { useEffect, useState } from 'react';

import { useConnectCartButton } from './connectors/connectCartButton';

const clsNameMap = createClsNameMap({
  elements: {
    subtotal: createClsNameMap({
      modifiers: ['right'],
    }),
    price: createClsNameMap({
      modifiers: ['medium'],
    }),
    label: createClsNameMap({
      modifiers: ['medium'],
    }),
    'btn-group': createClsNameMap({
      modifiers: ['right'],
    }),
    body: createClsNameMap({
      modifiers: ['bigger-space'],
    }),
    header: createClsNameMap({
      modifiers: ['bigger-space'],
    }),
    'items-count': createClsNameMap({
      modifiers: ['medium'],
    }),
    'item-empty': createClsNameMap(),
    footer: createClsNameMap({
      modifiers: ['right'],
    }),
    'clear-all-btn': createClsNameMap(),
  },
  modifiers: ['side'],
})('cart');

export type CartProps = {
  isOpen: boolean;
  handleCloseCart: () => void;
  onRender?: ReactNodeRenderer<CartData | undefined>;
  removeCartItem: (key: string) => void;
  cartData?: CartData;
  handleChangeQuantityCartItem: (key: string, quantity: number) => void;
};

const mergeCartStyle = (settingStyle?: CartStyle) => {
  if (isMobileWidth() || settingStyle === 'none') return 'side';

  return settingStyle || 'side';
};

const Cart = ({
  isOpen,
  handleCloseCart,
  onRender,
  removeCartItem,
  cartData,
  handleChangeQuantityCartItem,
}: CartProps) => {
  const { t } = useTranslation();

  const { enableCart, cartStyle: cartStyleSetting } = useCartThemeSettings();
  const { rateCurrency, unitCurrency } = useCurrency();

  if (!enableCart || cartStyleSetting === 'none') return <></>;

  const [cartStyle, setCartStyle] = useState<CartStyle>(mergeCartStyle(cartStyleSetting));

  const { props: checkoutBtnProps } = useConnectCartButton('checkout');
  const { props: viewCartBtnProps } = useConnectCartButton('viewCart');

  const onClickCheckout = (e: React.MouseEvent) => {
    setWindowLocation('/checkout');
  };

  const onClickViewCart = (e: React.MouseEvent) => {
    setWindowLocation(buildUrlWithLocalization('cart'));
  };

  useEffect(function updateCartStyleOnScreenRotate() {
    const listener = () => {
      setCartStyle(mergeCartStyle(cartStyleSetting));
    };

    if (screen.orientation) {
      screen.orientation.addEventListener('change', listener);

      return () => {
        screen.orientation.removeEventListener('change', listener);
      };
    } else {
      const portrait = window.matchMedia('(orientation: portrait)');

      portrait.addEventListener('change', listener);

      return () => {
        portrait.removeEventListener('change', listener);
      };
    }
  }, []);

  const generalTotal = () => {
    const totalAmount = formatMoney(
      (Number(cartData?.total_price) / 100) * rateCurrency,
      unitCurrency
    );
    return `${totalAmount}`;
  };

  return (
    <Modal
      hasCloseButton
      isOpen={isOpen}
      handleCloseModal={handleCloseCart}
      closeButtonModifiers={
        cartStyle === 'side' ? ['as-close-modal-side-cart'] : ['as-close-modal']
      }
    >
      <CustomizableNode renderer={onRender} payload={cartData}>
        <div className={mapModifiers(clsNameMap, { side: cartStyle === 'side' })}>
          <div
            className={mapModifiers(clsNameMap['header'], {
              'bigger-space': cartStyle === 'popup',
            })}
          >
            <div
              className={mapModifiers(clsNameMap['items-count'], {
                medium: cartStyle === 'popup',
              })}
            >
              {t('cart.atcMiniCartShopingCartLabel')} ({cartData?.item_count}{' '}
              {cartData?.item_count && cartData.item_count > 1
                ? t('cart.atcMiniCartCountItemLabelPlural')
                : t('cart.atcMiniCartCountItemLabel')}
              )
            </div>
          </div>
          <div
            className={mapModifiers(clsNameMap['body'], {
              'bigger-space': cartStyle === 'popup',
            })}
          >
            {cartData?.item_count && cartData.item_count > 0 ? (
              cartData?.items.map((item) => (
                <CartItem
                  data={item}
                  key={item.id}
                  isInPopup={cartStyle === 'popup'}
                  currency={cartData.currency}
                  handleRemoveCartItem={removeCartItem}
                  handleChangeQuantityCartItem={handleChangeQuantityCartItem}
                />
              ))
            ) : (
              <p className={clsNameMap.elm('item-empty')}>{t('cart.atcMiniCartEmptyCartLabel')}</p>
            )}
          </div>
          {!!cartData?.item_count && cartData.item_count > 0 && (
            <div
              className={mapModifiers(clsNameMap['footer'], {
                right: cartStyle === 'popup',
              })}
            >
              <div
                className={mapModifiers(clsNameMap.subtotal, {
                  right: cartStyle === 'popup',
                })}
              >
                <span
                  className={mapModifiers(clsNameMap.label, {
                    medium: cartStyle === 'popup',
                  })}
                >
                  {t('cart.atcMiniCartSubtotalLabel')}
                  {cartStyle === 'popup' && ':'}
                </span>
                <span
                  className={mapModifiers(clsNameMap['price'], {
                    medium: cartStyle === 'popup',
                  })}
                >
                  <InnerHTMLRenderer as='span' html={generalTotal()} />
                </span>
              </div>
              <div
                className={mapModifiers(clsNameMap['btn-group'], {
                  right: cartStyle === 'popup',
                })}
              >
                <CartButton
                  {...checkoutBtnProps}
                  onClick={onClickCheckout}
                  text={t('cart.atcMiniCartCheckoutLabel')}
                />
                <CartButton
                  {...viewCartBtnProps}
                  onClick={onClickViewCart}
                  text={t('cart.atcMiniCartViewCartLabel')}
                />
              </div>
            </div>
          )}
        </div>
      </CustomizableNode>
    </Modal>
  );
};

export default registryComponent('Cart', Cart);
