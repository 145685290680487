const LocalStorageKey = 'boostSDRecentlyViewedProduct';
const LIMIT = 40;

const validateProductLimit = () => {
  const productsRaw = window.localStorage.getItem(LocalStorageKey);

  if (!productsRaw) {
    window.localStorage.setItem(LocalStorageKey, JSON.stringify([]));
    return;
  }

  let products: Array<number> = JSON.parse(productsRaw);

  if (products.length > LIMIT) {
    products = products.slice(0, LIMIT);
    window.localStorage.setItem(LocalStorageKey, JSON.stringify(products));
  }
};

export const getRecentlyViewedProducts = (): Array<number> => {
  validateProductLimit();

  const productsRaw = window.localStorage.getItem(LocalStorageKey);
  if (!productsRaw) return [];

  return JSON.parse(productsRaw);
};

export const addRecentlyViewedProduct = (productId: number) => {
  const products = getRecentlyViewedProducts();
  products.unshift(productId);

  window.localStorage.setItem(LocalStorageKey, JSON.stringify(products));

  validateProductLimit();
};
