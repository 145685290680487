import type { ComponentRenderingPhase } from './component';
import type { ElementModel } from './element';

/**
 * TODO: improve implement with filter, ...
 */

export type ComponentLoggerConstructorParams<P> = {
  element: ElementModel<P>;
  enabled: boolean;
};

export type ComponentLoggerOptions = {
  source: string;
  onPhase: ComponentRenderingPhase;
};

export type ComponentLogger = {
  logAction: (action: () => unknown, options: ComponentLoggerOptions) => unknown;
};

export const defaultComponentLogger = <P>({
  element,
  enabled,
}: ComponentLoggerConstructorParams<P>): ComponentLogger => {
  const contextPathFormat = element.getRenderContextPath()?.join(' > ') || element.name;

  return {
    logAction: (action: () => unknown, { onPhase, source }: ComponentLoggerOptions) => {
      if (!enabled) {
        return action();
      }

      console.group(`Component: %c${element.name}`, 'color: cyan');

      console.group(`On: %c${onPhase}`, 'color: LightGreen');
      console.groupEnd();

      console.group(`Context: %c${contextPathFormat}`, 'color: orange');
      console.groupEnd();

      console.group(`From: %c${source}`, 'color: ivory');

      console.log('%cInput Rendering State', 'color: NavajoWhite', element.getParams());

      action();

      console.log('%cOutput Rendering State', 'color: NavajoWhite', element.getParams());

      console.groupEnd();

      console.groupEnd();
    },
  };
};
