import './ButtonTextX.scss';

import type { ModifiersType } from '@utils';
import { appendClassNames, createClsNameMap, mapModifiers } from '@utils';

export const clsNameMap = createClsNameMap({
  modifiers: [
    'close-style1',
    'close-style3',
    'clear-style1',
    'clear-style3',
    'as-close-modal',
    'as-close-modal-side-cart',
    'as-close-mini-popup',
    'clear-input-search-form',
    'clear-input-collection-search',
  ],
})('button-text-x');

export type ButtonTextXProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  value: string;
  className?: string;
  modifiers?: ModifiersType<typeof clsNameMap>;
};

/***
 * modifiers
 * 'close-style1' use for button close of mobiles style1
 * 'close-style3' use for button close of desktop style3
 * 'clear-style1' use for button clear of mobiles style1
 * 'clear-style3' use for button clear of desktop style3
 * ...
 */

const ButtonTextX = ({ className, value, modifiers, ...otherProps }: ButtonTextXProps) => {
  return (
    <button
      role={'textbox'}
      type={'button'} // prevent default type='submit' of button
      aria-label={value}
      className={`${appendClassNames('', className)} ${mapModifiers(clsNameMap, modifiers)}`}
      {...otherProps}
    >
      {value}
    </button>
  );
};

export default ButtonTextX;
