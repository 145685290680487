export const getGeneralSettingsFromAdmin = () => {
  return window.boostSDAppConfig.generalSettings;
};

export const getSearchSettingsFromAdmin = () => {
  return window.boostSDAppConfig.searchSettings;
};

export const getFilterSettingsFromAdmin = () => {
  return window.boostSDAppConfig.filterSettings;
};

export const getThemeSettingsFromAdmin = () => {
  return window.boostSDAppConfig.themeSettings;
};

export const getAdditionalElementSettingsFromAdmin = () => {
  return window.boostSDAppConfig.additionalElementSettings;
};

export type TAEFeaturesFromAdmin = {
  filterLayout?: {
    collection?: 'vertical' | 'horizontal';
    search?: 'vertical' | 'horizontal';
  };
  recommendationWidgets?: Record<string, Array<string>>;
  recommendation?: string;
  instantSearch?: string;
  filterCollection?: string;
  filterSearch?: string;
  env?: 'production' | 'staging';
};

export const getTAEFeatureFromAdmin = (): TAEFeaturesFromAdmin | undefined => {
  return window.boostSDAppConfig.themeInfo?.taeFeatures;
};

export const getAppConfig = () => {
  return window.boostSDAppConfig;
};
