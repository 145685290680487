import { useRef } from 'react';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

const useLatest = <T>(value: T, immediately?: boolean) => {
  const ref = useRef(value);

  if (immediately) {
    ref.current = value;
  }

  useIsomorphicLayoutEffect(() => {
    ref.current = value;
  });

  return ref;
};

export default useLatest;
