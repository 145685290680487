import type { ComponentRegistryBootstrap } from '@boost-sd/components-registry';
import { componentRegistryBootstrap } from '@boost-sd/components-registry/bootstrap';
import { castArray } from 'lodash-es';

export const bootstrapAppRegistry = () => {
  if ('__BoostCustomization__' in window) {
    castArray(window.__BoostCustomization__).forEach((bootstrap) =>
      componentRegistryBootstrap(bootstrap as ComponentRegistryBootstrap)
    );
  }
};
