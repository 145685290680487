import './Thumbs.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import Button from '@components/Button';
import type { PreviewImageOptions } from '@components/Slider';
import useTranslation from '@hooks/useTranslation';
import { createClsNameMap, mapModifiers } from '@utils';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

const clsNameMap = createClsNameMap({
  elements: {
    thumbs: createClsNameMap({
      modifiers: ['vertical', 'horizontal'],
    }),
    'prev-button': createClsNameMap({
      modifiers: ['vertical', 'horizontal'],
    }),
    'next-button': createClsNameMap({
      modifiers: ['vertical', 'horizontal'],
    }),
    'thumbs-item': createClsNameMap({
      modifiers: ['selected'],
    }),
  },
  modifiers: ['vertical', 'horizontal'],
})('thumbs-container');

export type SliderThumbsOptions = {
  numberOfItemToShow?: number;
  direction?: 'vertical' | 'horizontal';
};

export type SliderThumbsProps = {
  slides: Array<ReactNode>;
  selectedIndex: number;
  thumbsOptions?: SliderThumbsOptions;
  previewImageOptions?: PreviewImageOptions;
  onChange: (index: number) => void;
};

const DefaultArrowSliderIcon = `
<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.764024 5.51189L5.00002 1.28789L9.23602 5.51189" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const SliderThumbs = ({ slides, onChange, thumbsOptions, selectedIndex }: SliderThumbsProps) => {
  const { t } = useTranslation();

  const { numberOfItemToShow = 4, direction = 'vertical' } = thumbsOptions || {};

  const slidesLength = slides.length;
  const showNavigationButton = slides.length > numberOfItemToShow;

  const startIndexToShow = useMemo(() => {
    const groupToShow = Math.floor(selectedIndex / numberOfItemToShow);
    const firstIndexItemInShowGroup = groupToShow * numberOfItemToShow;
    const firstIndexItemInLastGroup = slidesLength - numberOfItemToShow;

    return Math.min(firstIndexItemInShowGroup, firstIndexItemInLastGroup);
  }, [selectedIndex, numberOfItemToShow, slidesLength]);

  const onClickPrevButton = () => {
    const newSelectedIndex = selectedIndex - 1;

    onChange(newSelectedIndex);
  };

  const onClickNextButton = () => {
    const newSelectedIndex = selectedIndex + 1;

    onChange(newSelectedIndex);
  };

  return (
    <div className={mapModifiers(clsNameMap, [direction])}>
      {showNavigationButton && (
        <div className={mapModifiers(clsNameMap['prev-button'], [direction])}>
          <Button
            aria-label={t('sliderProduct.prevButton')}
            icon={DefaultArrowSliderIcon}
            onClick={onClickPrevButton}
            disabled={selectedIndex === 0}
            buttonModifiers={['circle', 'full-width', 'no-padding']}
          />
        </div>
      )}

      <div className={mapModifiers(clsNameMap.thumbs, [direction])}>
        {slides.map(
          (item, index) =>
            index >= startIndexToShow &&
            index < startIndexToShow + numberOfItemToShow && (
              <div
                className={mapModifiers(clsNameMap['thumbs-item'], {
                  selected: index === selectedIndex,
                })}
                key={index}
                onClick={() => onChange(index)}
              >
                {item}
              </div>
            )
        )}
      </div>

      {showNavigationButton && (
        <div className={mapModifiers(clsNameMap['next-button'], [direction])}>
          <Button
            aria-label={t('sliderProduct.nextButton')}
            icon={DefaultArrowSliderIcon}
            onClick={onClickNextButton}
            disabled={selectedIndex === slides.length - 1}
            buttonModifiers={['circle', 'full-width', 'no-padding']}
          />
        </div>
      )}
    </div>
  );
};

export default registryComponent('SliderThumbs', SliderThumbs);
