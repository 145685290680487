import { createElement, useLayoutEffect, useRef } from 'react';

export type SlotDOMRenderProps = {
  elements: HTMLElement | Array<HTMLElement>;
};

export const SlotDOMRender = ({ elements }: SlotDOMRenderProps) => {
  const ref = useRef<HTMLDivElement>();
  const root = createElement('template', { ref });

  useLayoutEffect(() => {
    const element = ref.current;
    if (element && element.parentNode) {
      element.replaceWith(...(Array.isArray(elements) ? elements : [elements]));
    }
  });

  return root;
};

export default SlotDOMRender;
