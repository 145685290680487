import type { OptionsWithValues, ProductItem } from '@providers/ProductProvider';
import type { Dict } from '@types';
import { getI18n } from 'react-i18next';

import { getWindowLocation } from './browser';
import { isPageType, isSearchPage, isTagPage, isVendorPage } from './validate';

/** FOR MONEY FORMAT */

export const getTypeFormatMoney = (format?: string) => {
  const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  const formatString = format || '${{amount}}';
  const matches = formatString.match(placeholderRegex);
  const match = matches && matches.length > 1 ? matches[1] : '';

  return match;
};

export const formatMoneyByType = (type: string, money: number | string) => {
  function defaultOption(opt?: number | string, def?: number | string) {
    return typeof opt == 'undefined' ? def : opt;
  }

  function formatWithDelimiters(
    number: number | string | null,
    precision: number,
    thousands?: string,
    decimal?: string
  ) {
    if (number !== 0 && !number) return '';

    precision = defaultOption(precision, 2) as number;
    thousands = defaultOption(thousands, ',') as string;
    decimal = defaultOption(decimal, '.') as string;

    number = parseFloat(`${number}`).toFixed(precision);

    const parts = number.toString().split('.');
    const dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands);
    const money = parts[1] ? decimal + parts[1] : '';

    return dollars + money;
  }

  switch (type) {
    case 'amount':
      return formatWithDelimiters(money, 2);
    case 'amount_no_decimals':
      return formatWithDelimiters(money, 0);
    case 'amount_with_comma_separator':
      return formatWithDelimiters(money, 2, '.', ',');
    case 'amount_no_decimals_with_comma_separator':
      return formatWithDelimiters(money, 0, '.', ',');
    case 'amount_with_space_separator_no_comma':
      return formatWithDelimiters(money, 2);
    case 'amount_no_decimals_with_space_separator':
      return formatWithDelimiters(money, 0, ' ', '.');
    default:
      return formatWithDelimiters(money, 2);
  }
};

export const compileMoneyWithConfigFormat = (moneyFormat: string, format?: string) => {
  const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  let formatString = format || '${{amount}}';

  formatString = stripHtml(formatString).replace(placeholderRegex, moneyFormat);

  return formatString;
};

export const formatMoney = (
  money: number | string,
  format?: string,
  withTrailingZeros?: boolean,
  showCentAsSuperscript?: boolean,
  removePriceDecimal?: boolean,
  removeDecimalPoint?: boolean
) => {
  if (!format) format = window.boostSDAppConfig?.shop?.money_format;
  if (format === 'money_with_currency')
    format = window.boostSDAppConfig?.shop?.money_format_with_currency;
  if (typeof money === 'string') {
    money = money.replace('.', '');
  }

  // Get type format money
  const typeFormatMoney = getTypeFormatMoney(format);

  // Format money by type
  let moneyFormat = formatMoneyByType(typeFormatMoney, money);

  // Format money with trailing zeros
  if (!withTrailingZeros) moneyFormat = moneyFormat.replace(/((,00)|(\.00))$/g, '');

  // Remove decimal
  if (removePriceDecimal) {
    moneyFormat = removeDecimal(
      moneyFormat,
      window.boostSDAppConfig?.generalSettings?.decimalDelimiter
    );
  }

  // Format money with cent as superscript
  if (showCentAsSuperscript) {
    const [ints, cents] = separateDecimalMoney(moneyFormat, typeFormatMoney, removeDecimalPoint);
    if (cents) moneyFormat = `${ints}<sup>${cents}</sup>`;
  }

  // Replace config format with money format
  return compileMoneyWithConfigFormat(moneyFormat, format);
};

export const getDecimalPoint = (format: string) => {
  switch (format) {
    case 'amount':
    case 'amount_with_space_separator_no_comma':
      return '.';
    case 'amount_with_comma_separator':
      return ',';
    case 'amount_no_decimals':
    case 'amount_no_decimals_with_comma_separator':
    case 'amount_no_decimals_with_space_separator':
    default:
      return null;
  }
};

export const separateDecimalMoney = (
  moneyFormat: string | number,
  format: string,
  removeDecimalPoint?: boolean
) => {
  const decimalPoint = getDecimalPoint(format);
  if (!decimalPoint) return [`${moneyFormat}`];

  const [ints, cents] = `${moneyFormat}`.split(decimalPoint);

  return cents ? [ints, removeDecimalPoint ? `${cents}` : `${decimalPoint}${cents}`] : [ints];
};

/** END FOR MONEY FORMAT */

export const stripHtml = (string: string) => {
  return string?.toString()?.replace(/<.*?>/gi, '').replaceAll('&#36;', '$');
};

export const slugify = (text: string) => {
  let result = text;
  result = result.toLowerCase();

  // Remove Latin
  const from = 'àáäâãèéëêẽìíïîĩòóöôõùúüûũñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiiooooouuuuunc--_---';
  for (let i = 0, l = from.length; i < l; i++)
    result = result.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));

  // Remove Czech, Finnish special characters
  const specialCzechChars = 'ÁáÄäČčĎďÉéěÍíŇňÓóÖöŘřŠšŤťÚúůÝýŽž';
  const asciiCzechChars = 'AaAaCcDdEeeIiNnOoOoRrSsTtUuuYyZz';
  const specialCzechCharsLength = specialCzechChars.length;
  for (let j = 0; j < specialCzechCharsLength; j++) {
    result = result.replace(
      new RegExp(specialCzechChars.charAt(j), 'g'),
      asciiCzechChars.charAt(j)
    );
  }

  // Remove Norway special characters
  const specialNorwayChars = 'ÆæØøÅå';
  const asciiNorwayChars = ['AE', 'ae', 'O', 'o', 'A', 'a'];
  const specialNorwayCharsLength = specialNorwayChars.length;
  for (let k = 0; k < specialNorwayCharsLength; k++) {
    result = result.replace(new RegExp(specialNorwayChars.charAt(k), 'g'), asciiNorwayChars[k]);
  }

  // Remove quotes
  result = result.replace(/'/g, '').replace(/"/g, '');

  return result
    .replace(/[\s/]+/g, '-') // Replace spaces with -
    .replace(/[`~!@#$%^&*()|+\-=?;:'",.<>{}[\]\\/]/g, '-') // Replace all special characters with -
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const capitalize = (
  string: string,
  options?: { isLower?: boolean; onlyFirstLetter?: boolean }
) => {
  let result = string;

  if (options?.isLower) result = result.toLowerCase();

  if (options?.onlyFirstLetter) {
    result = result.charAt(0).toUpperCase() + string.slice(1);
  } else {
    result = result.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  }

  return result;
};

/**
 * Remove decimals from a number
 * @param {(string|number)} value The number value
 * @param {string} delimiter Delimiter symbol
 */
export const removeDecimal = (value: string | number, delimiter?: string) => {
  const reg = new RegExp('(\\' + delimiter + '\\d+)+', 'gi');
  return ('' + value).replace(reg, '');
};

/**
 * Escape a HTML string to displayed it
 * @param {String} string A string that contains HTML element.
 * @param {Boolean} preserveCR Set true if the end of line is carriage returns
 */
export const escape = (string: string, preserveCR?: boolean) => {
  const xmlConvert = preserveCR ? '&#13;' : '\n';
  return (
    ('' + string) /* Forces the conversion to string. */
      .replace(/&/g, '&amp;') /* This MUST be the 1st replacement. */
      .replace(/'/g, '&apos;') /* The 4 other predefined entities, required. */
      .replace(/"/g, '&quot;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      /*
		You may add other replacements here for HTML only
		(but it's not necessary).
		Or for XML, only if the named entities are defined in its DTD.
		*/
      .replace(/\r\n/g, xmlConvert) /* Must be before the next replacement. */
      .replace(/[\r\n]/g, xmlConvert)
  );
};

export const roundAmount = (amount: number, precision = 0, isMinPrice?: boolean): number => {
  if (amount < 0.04) return amount;

  if (isMinPrice) return Math.floor(amount * 100) / 100;

  return +amount.toFixed(precision);
};

export const formatPriceLabel = (
  to: string | number,
  from?: string | number,
  unitCurrency?: string,
  useZeroNotUnder?: boolean // use 0 instead of under when from === 0
) => {
  const formatPrice = (value: number) => {
    return formatMoney(value, unitCurrency);
  };

  const isFromZero = from === 0 && useZeroNotUnder;

  let label = '';
  if (!from && !isFromZero) {
    label = getI18n().t('under') + ` ${formatPrice(Number(to))}`;
  } else if (!to) {
    label = getI18n().t('above') + ` ${formatPrice(Number(from))}`;
  } else {
    label =
      Number(from) !== Number(to)
        ? `${formatPrice(Number(from))} - ${formatPrice(Number(to))}`
        : `${formatPrice(Number(from))} `;
  }

  return label;
};

export const formatPercentSaleLabel = (to: number, from?: number) => {
  let label = '';
  if (!from) {
    label = getI18n().t('under') + ` ${to}%`;
  } else if (!to) {
    label = getI18n().t('above') + ` ${from}%`;
  } else {
    label = `${from}% - ${to}%`;
  }

  return label;
};

export const formatRatingValue = (
  from: number,
  showExactRating?: boolean,
  docCount?: number
): string => {
  let adaLabel = from.toFixed();
  if (from === 1) adaLabel += ` ${getI18n().t('ratingStar')}`;
  else adaLabel += ` ${getI18n().t('ratingStars')}`;

  if (!showExactRating) adaLabel += ` ${getI18n().t('ratingUp')}`;
  if (docCount) adaLabel += `. Number of products: ${docCount}`;
  return stripHtml(adaLabel);
};

/**
 * Format a number separator
 * @param {Number} number - Number to Format
 * @param {Number} precision - Integer.
 * @param {string} thousand - Thousand separator
 * @param {string} decimal - Decimal separator
 * @param {boolean} withoutTrailingZeros - Removes .00 or .0
 * @return {string}
 */
export const formatNumberWithSeparator = (
  number: number,
  precision: number,
  thousand: string,
  decimal: string,
  withoutTrailingZeros: boolean
) => {
  if (isNaN(number)) number = 0;
  if (isNaN(precision) || Number.isInteger(number)) precision = 0;
  if (!decimal) {
    if (thousand === '.') {
      decimal = ',';
    } else {
      decimal = '.';
    }
  }

  const numberString = parseFloat(number + '').toFixed(precision);

  const parts = numberString.toString().split('.');
  let beforeDecimalString = parts[0];
  let afterDecimalString = parts[1] ? parts[1] : '';

  if (thousand) {
    beforeDecimalString = beforeDecimalString.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousand);
  }

  if (decimal && afterDecimalString) {
    if (withoutTrailingZeros && /0+/.test(afterDecimalString)) {
      afterDecimalString = '';
    } else {
      afterDecimalString = decimal + afterDecimalString;
    }
  }

  return beforeDecimalString + afterDecimalString;
};

/**
 * Build number label: separate thousands, decimal, fixed decimal places.
 * @param {Number} value
 * @param {boolean} isShortenNumber
 * @param {FilterOptionType} option
 * @param {Dict[]} formatPipsRange
 * @param {boolean} removePriceDecimal
 * @returns {string} Formatted value
 */
export const buildNumberLabel = (
  value: number,
  isShortenNumber: boolean,
  option: Dict,
  formatPipsRange: Dict[],
  removePriceDecimal: boolean,
  precision = 0
) => {
  if (isShortenNumber) {
    const ranges = formatPipsRange;
    if (Array.isArray(ranges) && ranges.length > 0) {
      let dividedValue = '0';
      let remainderValue = '';

      for (let i = ranges.length - 1; i >= 0; i--) {
        const range = ranges[i];
        if (value >= range.node) {
          dividedValue = Math.floor(value / range.node).toString();
          // parseInt -> remove composite number
          const valStr = parseInt('' + value).toString(),
            nodeStr = range.node.toString();
          const _start = valStr.length - nodeStr.length + 1; // Start from break node
          remainderValue = valStr.substring(_start, _start + range.fix);

          if (range.suffix) {
            return dividedValue + remainderValue + range.symbol;
          } else {
            return dividedValue + range.symbol + remainderValue;
          }
        }
      }
    }
  }

  const thousandSeparator = option.sliderDelimiter ? option.sliderDelimiter : '';
  let decimalSeparator = '.';
  if (thousandSeparator === '.') {
    decimalSeparator = ',';
  }

  let label = value.toFixed(precision).toString();

  const withoutTrailingZeros = !removePriceDecimal;
  label = formatNumberWithSeparator(
    value,
    precision,
    thousandSeparator,
    decimalSeparator,
    withoutTrailingZeros
  );

  return label;
};

export const buildUrlWithLocalization = (url?: string) => {
  if (!url) return '/';

  const routesRoot = window?.Shopify?.routes?.root || '/';

  return `${routesRoot}${url}`;
};

export const buildProductDetailUrl = (
  handle?: string,
  hasCollection?: boolean,
  current_tags?: string[]
): string => {
  if (!handle) return '/';

  const pathname = window.location.pathname;
  const elements = pathname.split('/');

  // Check if has locale
  let localeURLPart = '';
  localeURLPart = (window?.Shopify?.routes?.root || '/')?.replace(/\/$/, '');

  // check if have hasCollection
  if (hasCollection) {
    // Homepage or Search page
    if (pathname === '/' || isSearchPage() || isVendorPage() || isPageType()) {
      return `${localeURLPart}/collections/all/products/${handle}`;
    } else if (isTagPage(current_tags)) {
      const preHandle = localeURLPart + '/collections/';
      const collectionHandleIndex = elements.indexOf('collections') + 1;
      if (elements.length >= 4)
        return preHandle + elements[collectionHandleIndex] + '/products/' + handle;

      return `${localeURLPart}/products/${handle}`;
    } else {
      const params = getWindowLocation().search.substring(1);
      // Google cache URL
      // An URL of Google cache will look like this: webcache.googleusercontent.com/search?q=cache:xxx:https://xxx.xxx/collections/xxx+&....
      if (params.indexOf('cache:') > -1) {
        let collectionHandle = 'all';
        const temp = params.split('&')[0].split('?')[0].split('collections/');
        if (temp.length > 1) {
          if (temp[1].indexOf('/') > -1) {
            collectionHandle = temp[1].split('/')[0];
          } else {
            collectionHandle = temp[1];
          }
        }
        collectionHandle = collectionHandle.replace(
          /[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/g,
          ''
        );
        return '/collections/' + collectionHandle + '/products/' + handle;
      }

      //normal
      const collectionHandleIndex = elements.indexOf('collections') + 1;
      const preHandle = localeURLPart + '/collections/';
      if (typeof elements[2] !== 'undefined') {
        // Build for collection pages
        if (elements.includes('collections'))
          return preHandle + elements[collectionHandleIndex] + '/products/' + handle;
      }
    }
  }

  return `${localeURLPart}/products/${handle}`;
};

export const buildProductDetailUrlWithVariant = (
  data: Dict,
  hasCollection?: boolean,
  current_tags?: string[] | null,
  variantId?: string
) => {
  // Check if is split product by variant
  const variantIdPart =
    variantId || (data.split_product && data.variants)
      ? '?variant=' + (variantId || data.variant_id)
      : '';
  const productUrl = buildProductDetailUrl(data.handle, hasCollection, current_tags || undefined);

  return productUrl + variantIdPart;
};

/**
 * Format label with prefix
 * @param {(string)} value The string value
 */
export const formatLabelFilterOption = (label: string, prefix: string | undefined) => {
  if (prefix) {
    const newPrefix = prefix.replace(/\\/g, '');
    label = label.replace(newPrefix, '').trim();
  }
  return label;
};

export const addParamsLocale = (params: Record<string, unknown>) => {
  if (parseFloat(`${window.Shopify?.currency?.rate}`) === 1) return params;

  return {
    ...params,
    currency_rate: window.Shopify?.currency?.rate,
    currency: window.Shopify?.currency?.active,
    country: window.Shopify?.country,
    return_all_currency_fields: false,
  };
};

export const checkDefaultOption = (item: OptionsWithValues) => {
  /** If no have any option BE always return default option name title. This filter to hide default option */
  return !(
    item.label === 'Title' &&
    (item.original_name?.toLowerCase() || item.name) === 'title' &&
    item.values.length === 1 &&
    item.values?.[0].title === 'Default Title'
  );
};

export const removeDefaultOptionsProducts = (products: ProductItem[] = []) => {
  const newProducts = products.map((product: ProductItem) => {
    const options_with_values = product.options_with_values?.filter(checkDefaultOption);
    return { ...product, options_with_values };
  });

  return newProducts;
};
