import '@assets/scss/global.scss';

import { addRecentlyViewedProduct } from '@providers/ProductProvider/recentlyViewedProduct';

import { bootstrapAppModules } from '@/modules/bootstrap';

import { isCollectionPage, isProductPage, isSearchPage } from './exports/core';

export * from './exports/additional';
export * from './exports/cart';
export * from './exports/core';
export * from './exports/filter';
export * from './exports/product';
export * from './exports/recommendation';
export * from './exports/search';
export * from './exports/theme';

export const bootstrap = () => {
  bootstrapAppModules();

  // Add product in product detail page to recently viewed
  if (isProductPage() && window.boostSDData?.product) {
    addRecentlyViewedProduct(window.boostSDData.product.id);
  }

  // TODO: Remove animation in new version - temp solution
  if (isCollectionPage() || isSearchPage() || isProductPage()) {
    const animationClassesNewVersion =
      window?.boostSDAppConfig.generalSettings?.animationClassesNewVersion || '.animate--slide-in';

    const checkRemoveAnimationNewVersion = () => {
      const animationElements = document.querySelectorAll(animationClassesNewVersion);

      if (animationElements?.length === 0) return;

      Array.from(animationElements).forEach((element) => {
        element.classList?.forEach((cls: string) => {
          if (animationClassesNewVersion.includes(`.${cls}`)) {
            element.classList.remove(cls);
          }
        });
      });
    };

    // run first only one times
    checkRemoveAnimationNewVersion();

    document.addEventListener('scroll', () => checkRemoveAnimationNewVersion());
  }
};
