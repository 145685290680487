import type { AnyFunction } from '@boost-sd/core-js';
import { clone } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

import type { RenderContext } from './render-context';

export type SupportedRenderElement =
  | React.ReactNode
  | Array<React.ReactNode>
  | HTMLElement
  | Array<HTMLElement>;

export type ElementModelState<P> = {
  style?: React.CSSProperties | undefined;
  className?: string | undefined;
  props: P;
};

export type ElementAppliedPlugin<P> = {
  name: string;
  enabled?: boolean;
  options: {
    style?:
      | React.CSSProperties
      | ((style: React.CSSProperties, elementModel: ElementModel<P>) => React.CSSProperties);
    className?: string | ((className: string, elementModel: ElementModel<P>) => string);
    props?: P | ((props: P, elementMode: ElementModel<P>) => P);
    beforeInit?: (elementModel: ElementModel<P>) => unknown;
    afterInit?: (elementModel: ElementModel<P>) => unknown;
    render?: (
      elementModel: ElementModel<P>,
      currentRenderElement?: SupportedRenderElement
    ) => SupportedRenderElement;
    beforeRender?: (elementModel: ElementModel<P>) => unknown;
    afterRender?: (elementModel: ElementModel<P>) => unknown;
    unMount?: (elementModel: ElementModel<P>) => unknown;
  };
};

export type ElementModel<P> = {
  name: string;
  getHelpers?: () => Record<string, AnyFunction>;
  isInitialing: () => boolean;
  getRenderCount: () => number;
  render: () => void;
  getRootElm: () => HTMLElement | NodeListOf<ChildNode> | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getParentElm: () => ElementModel<any> | undefined;
  getParams: () => ElementModelState<P>;
  getAppliedPlugins: () => ElementAppliedPlugin<P>[];
  getRenderContextPath: () => string[] | undefined;
  getElmRenderContextValue: () => RenderContext<P> | null;
};

export const useElementModel = <P>(props: {
  name: string;
  renderState?: ElementModelState<P>;
  parent?: ElementModel<unknown>;
  plugins?: ElementAppliedPlugin<P>[];
  renderContextPaths?: string[];
  getElmRenderContextValue: () => RenderContext<P> | null;
}) => {
  const renderCountRef = useRef(0);
  const rootElementRef = useRef<HTMLElement | null>(null);
  const [, forceRerender] = useState({});

  const helpersFunctionsRef = useRef<Record<string, AnyFunction> | null>(null);

  const modelState: ElementModelState<P> = {
    style: props.renderState?.style || {},
    className: props.renderState?.className || '',
    props: {
      ...props.renderState?.props,
      helpersRef: (helpers: Record<string, AnyFunction>) => {
        helpersFunctionsRef.current = helpers;
      },
    } as P,
  };

  const model: ElementModel<P> = {
    name: props.name,
    isInitialing() {
      return renderCountRef.current === 0;
    },
    getHelpers() {
      return helpersFunctionsRef?.current || {};
    },
    getRenderCount() {
      return renderCountRef.current;
    },
    render() {
      forceRerender({});
    },
    getRootElm() {
      return rootElementRef.current;
    },
    getParentElm() {
      return props.parent;
    },
    getParams() {
      return clone(modelState);
    },
    getAppliedPlugins() {
      return props.plugins || [];
    },
    getRenderContextPath() {
      return props.renderContextPaths;
    },
    getElmRenderContextValue: props.getElmRenderContextValue,
  };

  const updateState = (updateData: Partial<ElementModelState<P>>) => {
    const nextState = {
      ...modelState,
      ...updateData,
    };

    Object.assign(modelState, nextState);
  };

  useEffect(() => {
    renderCountRef.current += 1;
  });

  useEffect(() => {
    const rootElement = model.getRootElm();

    if (rootElement instanceof HTMLElement) {
      const { style, className } = model.getParams();

      if (style) {
        Object.assign(rootElement.style, style);
      }

      if (className) {
        rootElement.classList.add(...className.split(' ').filter(Boolean));
      }
    }
  });

  return {
    element: model,
    rootElementRef,
    updateState,
  };
};
