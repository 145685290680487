import { useEffect, useState } from 'react';

export type DevicePixelRatioOptions = {
  defaultDpr?: number;
  round?: boolean;
  maxDpr?: number;
};

export function useDevicePixelRatio(options?: DevicePixelRatioOptions) {
  const dpr = getDevicePixelRatio(options);
  const [currentDpr, setCurrentDpr] = useState(dpr);
  const { defaultDpr, maxDpr, round } = options || {};

  useEffect(() => {
    const canListen = typeof window !== 'undefined' && 'matchMedia' in window;
    if (!canListen) {
      return;
    }

    const updateDpr = () => setCurrentDpr(getDevicePixelRatio({ defaultDpr, maxDpr, round }));
    const mediaMatcher = window.matchMedia(`screen and (resolution: ${currentDpr}dppx)`);

    mediaMatcher.addEventListener('change', updateDpr);

    return () => {
      mediaMatcher.removeEventListener('change', updateDpr);
    };
  }, [currentDpr, defaultDpr, maxDpr, round]);

  return currentDpr;
}

export function getDevicePixelRatio(options?: DevicePixelRatioOptions): number {
  const { defaultDpr = 1, maxDpr = 3, round = true } = options || {};
  const hasDprProp = typeof window !== 'undefined' && typeof window.devicePixelRatio === 'number';
  const dpr = hasDprProp ? window.devicePixelRatio : defaultDpr;
  const rounded = Math.min(Math.max(1, round ? Math.floor(dpr) : dpr), maxDpr);
  return rounded;
}
