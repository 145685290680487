import './Modal.scss';

import ButtonTextX from '@components/ButtonTextX';
import useTranslation from '@hooks/useTranslation';
import { createClsNameMap } from '@utils';
import type { PropsWithChildren } from 'react';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export const clsNameMap = createClsNameMap({
  elements: {
    backdrop: createClsNameMap(),
    container: createClsNameMap(),
    wrapper: createClsNameMap(),
    open: createClsNameMap(),
    'last-child': createClsNameMap(),
  },
})('modal');

export type ModalProps = {
  isOpen: boolean;
  hasCloseButton?: boolean;
  handleCloseModal: () => void;
} & {
  closeButtonModifiers?: ['as-close-modal-side-cart'] | ['as-close-modal'];
};

const Modal = ({
  isOpen,
  children,
  hasCloseButton,
  handleCloseModal,
  closeButtonModifiers,
  ...props
}: PropsWithChildren<ModalProps>) => {
  const { t } = useTranslation();

  const modalWrapperRef = useRef<HTMLDivElement>(null);

  const handleClickBackDrop = (e: React.MouseEvent<HTMLDivElement>) => {
    handleCloseModal();
  };

  const preventFocusOutsideModalByTab = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab') modalWrapperRef.current?.focus();
  };

  const handleCloseModalByEscapeKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') handleCloseModal();
  };

  useEffect(() => {
    if (isOpen) {
      modalWrapperRef.current?.focus();
    }
  }, [isOpen]);
  return createPortal(
    <>
      {isOpen && (
        <div className={clsNameMap.root()} aria-modal='true' role='dialog' aria-label='modal'>
          <div className={clsNameMap.elm('backdrop')} onClick={handleClickBackDrop}></div>
          <div className={clsNameMap.elm('container')}>
            <div
              tabIndex={-1}
              ref={modalWrapperRef}
              className={clsNameMap.elm('wrapper')}
              onKeyUp={handleCloseModalByEscapeKey}
            >
              {children}
              {hasCloseButton && (
                <ButtonTextX
                  value={t('quickView.qvCloseModal')}
                  onClick={handleCloseModal}
                  modifiers={closeButtonModifiers}
                />
              )}
              <div
                tabIndex={0}
                className={clsNameMap.elm('last-child')}
                onKeyUp={preventFocusOutsideModalByTab}
                onKeyDown={preventFocusOutsideModalByTab}
              />
            </div>
          </div>
        </div>
      )}
    </>,
    document.body
  );
};

export default Modal;
