import { CartAPI } from '@apis/cart';
import { useCartThemeSettings } from '@providers/ThemeProvider/Provider/CartThemeSettings';
import { setWindowLocation } from '@utils';
import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import Cart from '@/widgets/Cart';
import type { QuickViewProductData } from '@/widgets/QuickView';
import QuickView from '@/widgets/QuickView';

import type { CartData } from '../types';
import type { CartProviderProps } from '.';

const CartStateProvider = ({
  children,
  cartIconHeaderSelector,
  onUpdateCart,
}: PropsWithChildren<CartProviderProps>) => {
  const { enableCart, cartStyle, cartIconSelector, updateCartIconFunction } =
    useCartThemeSettings();

  const [cartData, setCartData] = useState<CartData>();
  const [isOpenCart, setIsOpenCart] = useState<boolean>(false);

  const [quickViewState, setQuickViewState] = useState<{
    data: QuickViewProductData;
    type: 'full' | 'mini';
  }>();

  const handleUpdateCart = (data: CartData) => {
    if (!data?.token) return;

    setCartData(data);

    window.dispatchEvent(
      new CustomEvent('boost-sd-set-cart-data', {
        detail: {
          cartData: data,
        },
      })
    );

    if (updateCartIconFunction) {
      updateCartIconFunction(data.item_count, data.total_price, data.currency);
    } else if (onUpdateCart) {
      onUpdateCart(data.item_count, data.total_price, data.currency);
    }
  };

  const handleGetCartData = () => {
    return CartAPI.getCartItems().then(handleUpdateCart);
  };

  const handleAddToCart = (
    variantId: string,
    quantity: number,
    onSuccess?: () => void,
    onFail?: () => void
  ) => {
    CartAPI.addToCart(variantId, quantity).then((res) => {
      if (res.status === 200) {
        if (enableCart) {
          handleGetCartData();
          if (cartStyle !== 'none')
            setTimeout(() => {
              setIsOpenCart(true);
            }, 350);
        } else {
          setWindowLocation('/cart');
        }

        if (onSuccess) onSuccess();
      } else {
        if (onFail) onFail();
      }
    });
  };

  const handleAddMultiProductToCart = (
    data: Array<{ id: string; quantity: number }>,
    onSuccess?: () => void,
    onFail?: () => void
  ) => {
    CartAPI.addMultiProductToCart(data).then((res) => {
      if (res.status === 200) {
        handleGetCartData();
        if (cartStyle !== 'none')
          setTimeout(() => {
            setIsOpenCart(true);
          }, 350);
        if (onSuccess) onSuccess();
      } else {
        if (onFail) onFail();
      }
    });
  };

  const handleChangeQuantityCartItem = (key: string, quantity: number) => {
    CartAPI.changeCartItem(key, quantity).then(handleUpdateCart);
  };

  const removeCartItem = (key: string) => {
    CartAPI.changeCartItem(key, 0).then(handleUpdateCart);
  };

  const handleClickCartIcon = (e: MouseEvent) => {
    e.preventDefault();
    setIsOpenCart(true);
  };

  const openQuickView = (data: QuickViewProductData, type: 'mini' | 'full') => {
    setQuickViewState({ data, type });
  };

  const closeQuickView = () => {
    setQuickViewState(undefined);
  };

  useEffect(() => {
    window.addEventListener('boost-sd-add-to-cart', (e) => {
      const { variantId, onFail, onSuccess, quantity } = e.detail;

      handleAddToCart(variantId, quantity, onSuccess, onFail);
    });

    window.addEventListener('boost-sd-open-quick-view', (e) => {
      const { productData, type } = e.detail;

      openQuickView(productData, type);
    });

    window.addEventListener('boost-sd-add-multi-product-to-cart', (e) => {
      const { data, onSuccess, onFail } = e.detail;

      handleAddMultiProductToCart(data, onSuccess, onFail);
    });

    return () => {
      window.removeEventListener('boost-sd-add-to-cart', (e) => {
        const { variantId, onFail, onSuccess, quantity } = e.detail;

        handleAddToCart(variantId, quantity, onSuccess, onFail);
      });

      window.removeEventListener('boost-sd-open-quick-view', (e) => {
        const { productData, type } = e.detail;

        openQuickView(productData, type);
      });

      window.removeEventListener('boost-sd-add-multi-product-to-cart', (e) => {
        const { data, onSuccess, onFail } = e.detail;

        handleAddMultiProductToCart(data, onSuccess, onFail);
      });
    };
  }, []);

  useEffect(() => {
    if (enableCart && cartStyle !== 'none') {
      handleGetCartData();

      const cartSelector = cartIconSelector || cartIconHeaderSelector;

      const cartIcons = cartSelector && document.querySelectorAll<HTMLElement>(cartSelector);

      if (!cartIcons) return;

      cartIcons.forEach((item) => item.addEventListener('click', handleClickCartIcon, true));

      return () => {
        cartIcons.forEach((item) => item.removeEventListener('click', handleClickCartIcon, true));
      };
    }
  }, []);

  return (
    <>
      {children}
      <Cart
        handleCloseCart={() => setIsOpenCart(false)}
        isOpen={isOpenCart}
        cartData={cartData}
        removeCartItem={removeCartItem}
        handleChangeQuantityCartItem={handleChangeQuantityCartItem}
      />
      <QuickView
        key={quickViewState?.data?.id}
        isOpen={!!quickViewState?.data}
        productData={quickViewState?.data}
        isMini={quickViewState?.type === 'mini'}
        handleCloseModalQuickView={closeQuickView}
      />
    </>
  );
};

export { CartStateProvider };
