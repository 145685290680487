import { isReactSupportRenderElement, SlotDOMRender } from '@boost-sd/core-js';
import { isValidElement } from 'react';

export type ReactNodeRenderer<P = undefined> =
  | string
  | React.ReactNode
  | ((payload: P) => React.ReactNode | HTMLElement | Array<HTMLElement>);

const CustomizableNode = <P = undefined,>(
  props: {
    hidden?: boolean;
    renderer?: ReactNodeRenderer<P>;
    children?: ReactNodeRenderer<P>;
  } & (P extends undefined
    ? {
        payload?: never;
      }
    : {
        payload: P;
      })
) => {
  const render = () => {
    const { renderer, children, hidden, payload } = props;

    if (hidden) return null;

    const renderNode = renderer || children;

    if (typeof renderNode === 'string' || isValidElement(renderNode)) return renderNode;

    if (typeof renderNode === 'function') {
      const nodes = renderNode(payload as P);

      if (isReactSupportRenderElement(nodes)) return nodes;

      if (nodes instanceof Node || nodes instanceof NodeList || Array.isArray(nodes)) {
        return <SlotDOMRender elements={nodes} />;
      }

      return nodes;
    }

    return null;
  };

  return <>{render()}</>;
};

export default CustomizableNode;
