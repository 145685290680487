import './Slider.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { useComponentStatus } from '@boost-sd/core-js/react/hooks';
import SliderDots from '@components/SliderDots';
import SliderNextButton from '@components/SliderNextButton';
import SliderPrevButton from '@components/SliderPrevButton';
import SliderSlide from '@components/SliderSlide';
import type { SliderThumbsOptions } from '@components/SliderThumbs';
import SliderThumbs from '@components/SliderThumbs';
import loadable from '@loadable/component';
import { createClsNameMap, mapModifiers } from '@utils';
import { debounce } from 'lodash-es';
import type { ReactNode } from 'react';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import type { Settings } from 'react-slick';

const SlickSlider = loadable(
  () =>
    import(
      /* webpackChunkName: "slider--slick"*/
      'react-slick'
    )
);

type SlickSliderType = any;

const clsNameMap = createClsNameMap({
  modifiers: ['flex-column', 'thumb-vertical', 'disable-arrow-buttons'],
  elements: {
    title: createClsNameMap({
      modifiers: ['dashed', 'line', 'center', 'left'],
    }),
    'title-text': createClsNameMap(),
  },
})('slider');

export type PreviewImageOptions = {
  altImage?: string;
};

export type SliderProps<SlidePayload> = {
  hasPagination?: boolean;
  thumbs?: {
    enable?: boolean;
    thumbsOptions?: SliderThumbsOptions;
  };
  style?: React.CSSProperties;
  slidesToShow?: number;
  enableButtons?: boolean;
  customPrevButton?: ReactNode;
  customNextButton?: ReactNode;
  positionButtons?: 'inside' | 'outside';
  previewImageOptions?: PreviewImageOptions;
  slides: SlidePayload[];
  onSlideRender: (payload: SlidePayload, index: number) => React.ReactNode | React.ReactNode[];
  onChange?: (index: number) => void;
  infinite?: boolean;
  fixedButtonPosition?: boolean;
  responsive?: { breakpoint: number; settings: Settings }[];
  useTransform?: boolean;
};

export const SliderContext = createContext({});

const Slider = <SlidePayload = unknown,>({
  onSlideRender,
  slides,
  thumbs,
  hasPagination,
  onChange,
  infinite = true,
  slidesToShow = 1,
  enableButtons,
  positionButtons,
  customNextButton,
  customPrevButton,
  style,
  fixedButtonPosition,
  responsive = [],
  useTransform,
}: SliderProps<SlidePayload>) => {
  const { getRenderCount } = useComponentStatus();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const slider = useRef<SlickSliderType | null>(null);
  const [buttonPosition, setButtonPosition] = useState<
    | {
        top?: number;
        left?: number;
        right?: number;
        bottom?: number;
      }
    | undefined
  >(undefined);

  const { enable: enabledThumbs = false, thumbsOptions } = thumbs || {};

  const slidesToShowOnDevice = useMemo(() => {
    const deviceWidth = window.innerWidth;
    let result = slidesToShow;

    responsive?.some(({ breakpoint, settings }) => {
      if (deviceWidth <= breakpoint) {
        result = settings.slidesToShow || slidesToShow;
        return true;
      }
      return false;
    });

    return result;
  }, [slidesToShow, responsive]);

  const numberOfGroupItems = useMemo(() => {
    const totalItems = slides.length;

    return Math.ceil(totalItems / slidesToShowOnDevice);
  }, [slidesToShowOnDevice, slides.length]);

  const handleChangeSelectedIndex = (index: number) => {
    slider.current?.slickGoTo(index);

    if (onChange) {
      onChange(index);
    }
  };

  const slideNodes = useMemo(() => {
    return slides.map((payload, index) => (
      <SliderSlide key={index} index={index} payload={payload} onRender={onSlideRender} />
    ));
  }, [slides]);

  const adaptHeight = debounce(() => {
    let maxHeight = 0;
    const innerSlideList = slider.current?.innerSlider?.list;
    if (!innerSlideList) return;

    for (let i = 0; i < slidesToShowOnDevice; i++) {
      const slideIndex = selectedIndex + i;
      const elem = innerSlideList.querySelector(`[data-index="${slideIndex}"]`);
      if (elem instanceof HTMLElement) {
        maxHeight = Math.max(elem.offsetHeight, maxHeight);
      }
    }

    innerSlideList.style.height = maxHeight + 'px';
  }, 300);

  const sliderSettings: Settings = {
    dots: false,
    arrows: false,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    infinite,
    beforeChange: (_, nextSlideIndex) => {
      setSelectedIndex(nextSlideIndex);
    },
    responsive: responsive,
    useTransform,
  };

  useEffect(() => {
    window.addEventListener('resize', adaptHeight);
    const resizeObserver = new ResizeObserver(() => {
      adaptHeight();
      const listElement = slider.current?.innerSlider?.list;

      if (selectedIndex === 0 && listElement && fixedButtonPosition && !buttonPosition) {
        setButtonPosition({
          top: listElement.offsetHeight / 2,
        });
      }
    });

    const listElement = slider.current?.innerSlider?.list;

    if (listElement) {
      resizeObserver.observe(listElement);
    }
    return () => {
      window.removeEventListener('resize', adaptHeight);
      resizeObserver.disconnect();
    };
  }, [selectedIndex, slidesToShowOnDevice, fixedButtonPosition]);

  const slickSliderRefHandler = (slickSlider: SlickSliderType) => {
    slider.current = slickSlider;
    if (getRenderCount() === 1 && slickSlider) {
      adaptHeight();
    }
  };

  return (
    <div
      className={mapModifiers(clsNameMap, {
        'flex-column': enabledThumbs && thumbsOptions?.direction === 'horizontal',
        'thumb-vertical': enabledThumbs && thumbsOptions?.direction === 'vertical',
        'disable-arrow-buttons': !enableButtons,
      })}
      style={style}
    >
      {enabledThumbs && (
        <SliderThumbs
          thumbsOptions={thumbsOptions}
          selectedIndex={selectedIndex}
          slides={slideNodes}
          onChange={handleChangeSelectedIndex}
        />
      )}
      <SlickSlider ref={slickSliderRefHandler} {...sliderSettings}>
        {slideNodes}
      </SlickSlider>
      {enableButtons && (
        <>
          <SliderPrevButton
            customEl={customPrevButton}
            position={positionButtons}
            disabled={!infinite && selectedIndex === 0}
            onChange={() => slider.current?.slickPrev()}
            style={buttonPosition}
          />
          <SliderNextButton
            customEl={customNextButton}
            position={positionButtons}
            disabled={!infinite && selectedIndex + slidesToShowOnDevice >= slides.length}
            onChange={() => slider.current?.slickNext()}
            style={buttonPosition}
          />
        </>
      )}
      {hasPagination && (
        <SliderDots
          dots={numberOfGroupItems}
          slidesToShow={slidesToShowOnDevice}
          selectedSlide={selectedIndex}
          handleChangeSlide={handleChangeSelectedIndex}
        />
      )}
    </div>
  );
};

export default registryComponent('Slider', Slider) as <Slide>(
  props: SliderProps<Slide>
) => JSX.Element;
