import { Component, createElement, useLayoutEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';

export class LegacyDOMRepresentation extends Component<{
  innerRef: React.MutableRefObject<HTMLElement | NodeListOf<ChildNode> | Text | null>;
  children: React.ReactNode;
}> {
  componentDidUpdate(): void {
    try {
      const node = findDOMNode(this);

      if (node instanceof Element || node instanceof NodeList || node instanceof Text) {
        Object.assign(this.props.innerRef, {
          current: node,
        });
      }
    } catch {
      // Do nothing
    }
  }

  componentDidMount(): void {
    try {
      const node = findDOMNode(this);

      if (node instanceof Element || node instanceof NodeList || node instanceof Text) {
        Object.assign(this.props.innerRef, {
          current: node,
        });
      }
    } catch {
      // Do nothing
    }
  }

  render(): React.ReactNode {
    return this.props.children;
  }
}

export const DOMRepresentation: React.FC<{
  children: React.ReactNode;
  innerRef: React.MutableRefObject<HTMLElement | NodeListOf<ChildNode> | null>;
}> = ({ children, innerRef }) => {
  const ref = useRef<HTMLDivElement>();
  const root = createElement(
    'div',
    {
      ref,
    },
    children
  );

  useLayoutEffect(() => {
    const element = ref.current;
    if (element && element.parentNode) {
      const childNodes = element.childNodes;

      if (!childNodes.length) return;

      if (childNodes.length > 1) {
        innerRef.current = childNodes;
      } else if (childNodes[0] instanceof HTMLElement) {
        innerRef.current = childNodes[0];
      }

      element.replaceWith(...childNodes);
    }
  });

  return root;
};

export default LegacyDOMRepresentation;
