export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const isMobileWidth = () => {
  // TODO get width from settings
  return window.innerWidth < 768;
};

export const isDesktopWidth = (breakpointTablet = 1199) => {
  // TODO get width from settings
  if (window.innerWidth > breakpointTablet) return true;
  return false;
};

export const isTabletWidth = (breakpointMobile = 767, breakpointTablet = 1199) => {
  // TODO get width from settings
  if (breakpointMobile < window.innerWidth && window.innerWidth <= breakpointTablet) return true;
  return false;
};

export const getWindowLocation = () => {
  // Escape window.location.href
  const href = window.location.href;
  const escapedHref = href.replace(/%3C/g, '&lt;').replace(/%3E/g, '&gt;');

  // Rebuild window.location.href
  const rebuildHrefArr: string[] = [];
  for (let i = 0; i < escapedHref.length; i++) {
    rebuildHrefArr.push(escapedHref.charAt(i));
  }
  const rebuildHref = rebuildHrefArr.join('').split('&lt;').join('%3C').split('&gt;').join('%3E');

  // Rebuild window.location.search
  let rebuildSearch = '';
  const hrefWithoutHash = rebuildHref.replace(/#.*$/, '');
  if (hrefWithoutHash.split('?').length > 1) {
    rebuildSearch = hrefWithoutHash.split('?')[1];
    if (rebuildSearch.length > 0) {
      rebuildSearch = '?' + rebuildSearch;
    }
  }

  return {
    pathname: window.location.pathname,
    href: rebuildHref,
    search: rebuildSearch,
  };
};

export const setWindowLocation = (url: string) => {
  window.location.href = url;
};

export const getLocalStorage = (key: string) => {
  try {
    const value = localStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
};

export const setLocalStorage = (key: string, value: unknown) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    localStorage.setItem(key, '');
  }
};

export const getSessionStorage = (key: string) => {
  try {
    const value = sessionStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
};

export const setSessionStorage = (key: string, value: unknown) => {
  try {
    if (!value) {
      sessionStorage.removeItem(key);
      return;
    }

    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    sessionStorage.setItem(key, '');
  }
};
