import type { Dict, FunctionMembers } from '@boost-sd/core-js';

export type ModuleMethodEvents =
  | 'beforeMethodCall'
  | 'methodPending'
  | 'methodFulfilled'
  | 'methodReject';

export type ModuleEvents = ModuleMethodEvents;

export type ModuleMethodEventPayload<Args = undefined, R = unknown, E = Error> =
  | {
      args: Args;
      result: R | null;
      error: null;
    }
  | {
      args: Args;
      result: null;
      error: E;
    };

export type ModulePluginBuilders<P extends Dict> = {
  on: <T extends FunctionMembers<P>>(
    event: ModuleMethodEvents,
    target: T,
    handler: (payload: ModuleMethodEventPayload<Parameters<P[T]>, ReturnType<P[T]>, Error>) => void
  ) => unknown;
};

export type ModulePlugin<P extends Dict> = {
  name: string;
  enabled: boolean;
  hooks: {
    methods: {
      [name in FunctionMembers<P>]: {
        [event in ModuleMethodEvents]?: Set<
          (
            payload: ModuleMethodEventPayload<Parameters<P[name]>, ReturnType<P[name]>, Error>
          ) => void
        >;
      };
    };
  };
};

export type ModulePluginOptions<P extends Dict> = {
  name: string;
  enabled: boolean;
  apply: (builder: ModulePluginBuilders<P>) => unknown;
};

export const createModulePlugin = <P extends Dict>(
  pluginOptions: ModulePluginOptions<P>,
  moduleProps: P
) => {
  const plugin: ModulePlugin<P> = {
    name: pluginOptions.name,
    enabled: pluginOptions.enabled,
    hooks: {
      methods: {},
    } as ModulePlugin<P>['hooks'],
  };

  const methodHooks = plugin.hooks.methods;

  Object.keys(moduleProps).forEach((key) => {
    if (typeof moduleProps[key] === 'function') {
      methodHooks[key as FunctionMembers<P>] = {
        beforeMethodCall: new Set(),
        methodFulfilled: new Set(),
        methodPending: new Set(),
        methodReject: new Set(),
      };
    }
  });

  return plugin;
};
