import './Dots.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { createClsNameMap, mapModifiers } from '@utils';

const clsNameMap = createClsNameMap({
  elements: {
    item: createClsNameMap({
      modifiers: ['active'],
    }),
  },
})('dots');

export type SliderDotsProps = {
  handleChangeSlide: (index: number) => void;
  slidesToShow: number;
  selectedSlide: number;
  dots: number;
};

const SliderDots = ({ dots, slidesToShow, selectedSlide, handleChangeSlide }: SliderDotsProps) => {
  return (
    <div className={clsNameMap.root()}>
      {Array(dots)
        .fill(null)
        .map((_, index) => (
          <div
            key={index}
            onClick={() => handleChangeSlide(index * slidesToShow)}
            className={mapModifiers(clsNameMap.item, {
              active: Math.floor(selectedSlide / slidesToShow) === index,
            })}
          />
        ))}
    </div>
  );
};

export default registryComponent('SliderDots', SliderDots);
