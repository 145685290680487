import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ImgHTMLAttributes } from 'react';

export type ImageProps = React.DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  id?: string;
  className?: string;
  src: string;
  alt: string;
  decoding?: ImgHTMLAttributes<HTMLImageElement>['decoding'];
  loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
  sizes?: string;
  srcSet?: string;
};

const Image = ({
  id,
  className,
  src,
  alt,
  decoding = 'async',
  loading = 'lazy',
  sizes,
  srcSet,
  ...imgAttrs
}: ImageProps) => {
  return (
    <img
      {...imgAttrs}
      id={id}
      className={className}
      loading={loading}
      decoding={decoding}
      alt={alt}
      src={src}
      srcSet={srcSet}
      sizes={sizes}
    />
  );
};

export default registryComponent('Image', Image);
