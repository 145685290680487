import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ReactNodeRenderer } from '@components/CustomizableNode';
import CustomizableNode from '@components/CustomizableNode';
import Radio from '@components/Radio';
import useGeneralSettings from '@hooks/useGeneralSettings';
import { createClsNameMap, mapModifiers, slugify } from '@utils';
import { uniqueId } from 'lodash-es';
import { useRef } from 'react';

const clsNameMap = createClsNameMap({
  modifiers: ['selected'],
})('product-swatch-option');

export type ProductSwatchOptionValue = string;

export type ProductSwatchOptionDisplayType = 'box' | 'circle';

export type ProductSwatchOptionProps<Value extends ProductSwatchOptionValue> = {
  value: Value;
  image?: string;
  selected?: boolean;
  onSelect?: (value: Value, image?: string) => unknown;
  onHover?: (value: Value, image?: string) => void;
  onBlur?: () => void;
  label?: string;
  id?: string;
  labelAs?: 'text' | 'color' | 'image' | 'product-image';
  onLabelRender?: ReactNodeRenderer<{ handleSelectOption: () => void }>;
  labelStyle?:
    | React.CSSProperties
    | ((
        payload: Pick<ProductSwatchOptionProps<Value>, 'value' | 'selected' | 'displayType'>
      ) => React.CSSProperties);
  border?: boolean | React.CSSProperties['border'];
  displayType?: ProductSwatchOptionDisplayType;
  showOptionValueOnHovering?: boolean;
  isPopupSelectOption?: boolean;
  isQuickView?: boolean;
  customSwatchImageUrl?: (fileBaseUrl: string) => string;
};

export const ProductSwatchOption = <Value extends ProductSwatchOptionValue>({
  id,
  value,
  label,
  labelAs = 'text',
  labelStyle,
  onLabelRender,
  selected,
  onSelect,
  onHover,
  onBlur,
  displayType = 'circle',
  showOptionValueOnHovering,
  image,
  isPopupSelectOption,
  isQuickView,
  customSwatchImageUrl,
}: ProductSwatchOptionProps<Value>) => {
  const { fileBaseUrl } = useGeneralSettings();
  const uuid = useRef<string>(uniqueId());

  const handleSelectOption = () => {
    if (onSelect) onSelect(value, image);
  };

  const handleHover = () => {
    if (onHover) onHover(value, image);
  };

  const backgroundImage = () => {
    if (labelAs === 'image') {
      if (customSwatchImageUrl) {
        return customSwatchImageUrl(fileBaseUrl);
      } else {
        const valueValidate = slugify(value || '')
          ?.toString()
          .replaceAll(' ', '-')
          .toLocaleLowerCase();
        return `url(${fileBaseUrl}${valueValidate}.png)`;
      }
    }
    if (labelAs === 'product-image') {
      return `url(${image})`;
    }
    return undefined;
  };

  return (
    <div
      className={mapModifiers(clsNameMap, {
        selected,
      })}
    >
      <CustomizableNode renderer={onLabelRender} payload={{ handleSelectOption }}>
        <Radio
          id={`${id}-${uuid.current}`}
          label={label}
          value={value}
          checked={selected}
          onChange={handleSelectOption}
          onMouseEnter={handleHover}
          onMouseLeave={onBlur}
          inputModifiers={['hidden']}
          labelModifiers={{
            'non-radius': displayType === 'box' || (labelAs === 'text' && isPopupSelectOption),
            'hide-text': labelAs !== 'text',
            large: labelAs !== 'text' && isQuickView,
            selected: selected,
            'in-quick-view': labelAs === 'text' && isQuickView,
          }}
          tooltip={showOptionValueOnHovering}
          labelProps={{
            style: {
              backgroundColor:
                labelAs === 'color' || labelAs === 'image' ? value.toLowerCase() : undefined,
              backgroundImage: backgroundImage(),
              ...(typeof labelStyle === 'function'
                ? labelStyle({ value, selected, displayType })
                : labelStyle),
            },
          }}
        />
      </CustomizableNode>
    </div>
  );
};

export default registryComponent('ProductSwatchOption', ProductSwatchOption) as <
  I extends ProductSwatchOptionValue
>(
  props: ProductSwatchOptionProps<I>
) => JSX.Element;
