import { isValidElement } from 'react';

export const isReactSupportRenderElement = (
  nodes: unknown
): nodes is React.ReactNode | React.ReactNode[] => {
  if (Array.isArray(nodes)) {
    return nodes.some((node) => isValidElement(node));
  }

  return isValidElement(nodes);
};
