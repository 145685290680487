import { clone } from 'lodash-es';

import type { ElementAppliedPlugin, ElementModel, SupportedRenderElement } from './element';

export type ComponentRenderingPhase =
  | 'transform'
  | 'before-init'
  | 'before-render'
  | 'render'
  | 'after-init'
  | 'after-render'
  | 'unmount';

export type ComponentPluginOptions<P> = {
  name: string;
  enabled?: boolean;
  apply: () => ElementAppliedPlugin<P>['options'];
};

export type ComponentPlugin<P> = {
  name: string;
  enabled?: boolean;
  options: ElementAppliedPlugin<P>['options'];
};

export type ComponentContext<P> = {
  debug?: boolean;
  plugins: ComponentPlugin<P>[];
  children: Record<string, ComponentContext<P>>;
};

export type ComponentModel<P> = {
  name: string;
  debug?: boolean;
  plugins: ComponentPlugin<P>[];
  contexts: Record<string, ComponentContext<P>>;
  CustomizedComponentHOC?: React.ComponentType<P>;
};

export function applyComponentPlugin<P>(
  pluginOptions: ComponentPlugin<P>['options'],
  on: 'transform',
  elementModel: ElementModel<P>
): {
  props: P;
  style: React.CSSProperties | undefined;
  className: string | undefined;
};

export function applyComponentPlugin<P>(
  pluginOptions: ComponentPlugin<P>['options'],
  on: 'render',
  elementModel: ElementModel<P>,
  currentRenderElement?: SupportedRenderElement
): SupportedRenderElement;

export function applyComponentPlugin<P>(
  pluginOptions: ComponentPlugin<P>['options'],
  on: ComponentRenderingPhase,
  elementModel: ElementModel<P>
): void;

export function applyComponentPlugin<P>(
  pluginOptions: ComponentPlugin<P>['options'],
  on: ComponentRenderingPhase,
  elementModel: ElementModel<P>,
  currentRenderElement?: SupportedRenderElement
) {
  switch (on) {
    case 'transform': {
      const {
        props: inputProps,
        style: inputStyle,
        className: inputClassName,
      } = elementModel.getParams();

      const {
        props: propsTransformer,
        style: styleTransformer,
        className: classNameTransformer,
      } = pluginOptions;

      let props = inputProps;
      let style = inputStyle;
      let className = inputClassName;

      if (propsTransformer) {
        const cloneProps = clone(props);
        props =
          propsTransformer instanceof Function
            ? propsTransformer(cloneProps, elementModel)
            : propsTransformer;
      }

      if (styleTransformer) {
        style =
          styleTransformer instanceof Function
            ? styleTransformer(clone(inputStyle || {}), elementModel)
            : styleTransformer;
      }

      if (classNameTransformer) {
        className =
          classNameTransformer instanceof Function
            ? classNameTransformer(inputClassName || '', elementModel)
            : classNameTransformer;
      }

      return {
        props,
        style,
        className,
      };
    }

    case 'before-init': {
      if (pluginOptions.beforeInit && elementModel.isInitialing()) {
        pluginOptions.beforeInit(elementModel);
      }

      break;
    }

    case 'before-render': {
      if (pluginOptions.beforeRender) {
        pluginOptions.beforeRender(elementModel);
      }

      break;
    }

    case 'after-init': {
      if (pluginOptions.afterInit && elementModel.getRenderCount() === 1) {
        pluginOptions.afterInit(elementModel);
      }

      break;
    }

    case 'after-render': {
      if (pluginOptions.afterRender) {
        pluginOptions.afterRender(elementModel);
      }

      break;
    }

    case 'render': {
      if (pluginOptions.render) {
        return pluginOptions.render(elementModel, currentRenderElement);
      }
      break;
    }

    case 'unmount': {
      if (pluginOptions.unMount) {
        pluginOptions.unMount(elementModel);
      }

      break;
    }

    default:
      break;
  }
}
