import './Quantity.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import Button from '@components/Button';
import useTranslation from '@hooks/useTranslation';
import type { ModifiersType } from '@utils';
import { createClsNameMap, mapModifiers } from '@utils';
import type { HTMLProps } from 'react';

export const clsNameMap = createClsNameMap({
  elements: {
    input: createClsNameMap({
      modifiers: ['small'],
    }),
    'btn-wrapper': createClsNameMap({ modifiers: ['small'] }),
    error: createClsNameMap(),
  },
  modifiers: ['medium', 'small'],
})('quantity');

export type QuantityProps = Omit<
  HTMLProps<HTMLInputElement>,
  'value' | 'defaultValue' | 'onChange'
> & {
  error?: boolean;
  value: number | '';
  onChange: (value: number | '') => void;
  isCheckSideCart?: boolean;
  modifier?: ModifiersType<typeof clsNameMap, true>;
};

const Quantity = ({
  value,
  error,
  onChange,
  isCheckSideCart,
  modifier,
  ...otherProps
}: QuantityProps) => {
  const { t } = useTranslation();

  const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target?.value === '' ? '' : Number(e.target.value));
  };

  const handleClickDecrease = () => {
    onChange(value === '' ? 1 : value > 1 ? value - 1 : 1);
  };

  const handleClickIncrease = () => {
    onChange(value === '' ? 1 : value + 1);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Prevent input 'e', 'E', '+', '-', '.'
    return ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
  };

  return (
    <>
      <div className={mapModifiers(clsNameMap, modifier)}>
        <div className={mapModifiers(clsNameMap['btn-wrapper'], { small: isCheckSideCart })}>
          <Button onClick={handleClickDecrease} buttonModifiers={['as-quantity', 'full-width']}>
            -
          </Button>
        </div>

        <input
          type='number'
          aria-label={t('cart.atcMiniCartItemQuantity')}
          value={value}
          className={mapModifiers(clsNameMap.input, { small: isCheckSideCart })}
          onChange={handleChangeQuantity}
          onKeyDown={handleOnKeyDown}
        />
        <div className={mapModifiers(clsNameMap['btn-wrapper'], { small: isCheckSideCart })}>
          <Button onClick={handleClickIncrease} buttonModifiers={['as-quantity', 'full-width']}>
            +
          </Button>
        </div>
      </div>
      {error && <div className={clsNameMap.elm('error')}>t('quickView.qvQuantityError')</div>}
    </>
  );
};

export default registryComponent('Quantity', Quantity);
