import './Radio.scss';

import Tooltip from '@components/Tooltip';
import type { ModifiersType } from '@utils';
import { createClsNameMap, mapModifiers } from '@utils';
import type { HTMLProps } from 'react';
import { useRef } from 'react';
export const clsNameMap = createClsNameMap({
  elements: {
    tooltip: createClsNameMap(),
    input: createClsNameMap({
      modifiers: ['hidden'],
    }),
    label: createClsNameMap({
      modifiers: ['non-radius', 'hide-text', 'selected', 'large', 'in-quick-view'],
    }),
  },
})('radio');

export type RadioProps = HTMLProps<HTMLInputElement> & {
  tooltip?: boolean | React.ReactNode;
  labelModifiers?: ModifiersType<(typeof clsNameMap)['label'], true>;
  labelProps?: React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  label?: React.ReactNode;
  inputModifiers?: ModifiersType<(typeof clsNameMap)['input'], true>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const Radio = ({
  label,
  checked,
  tooltip,
  value,
  onChange,
  onMouseEnter,
  onMouseLeave,
  labelModifiers,
  labelProps,
  inputModifiers,
  ...inputProps
}: RadioProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onKeyUp = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    // Select radio by Enter or Space or Spacebar key
    if (['Enter', ' ', 'Spacebar'].includes(e.key)) inputRef.current?.click();
  };

  return (
    <div className={clsNameMap.root()} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Tooltip content={label?.toString() || ''} disabled={!tooltip}>
        <input
          {...inputProps}
          className={mapModifiers(clsNameMap['input'], inputModifiers)}
          ref={inputRef}
          type='radio'
          checked={checked}
          value={value}
          onChange={onChange}
        />
        <label
          {...labelProps}
          tabIndex={0}
          htmlFor={inputProps.id}
          className={mapModifiers(clsNameMap.label, labelModifiers)}
          onKeyUp={onKeyUp}
        >
          {label}
        </label>
      </Tooltip>
    </div>
  );
};

export default Radio;
