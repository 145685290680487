import type { RegistryComponentProps } from '@boost-sd/components-registry';
import { registryComponent } from '@boost-sd/components-registry/registry';
import type { DeepPartial } from '@types';
import { merge } from 'lodash-es';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useImperativeHandle, useState } from 'react';

import type { QuickViewThemeSettings } from '../types';

const QuickViewThemeSettingsContext = createContext<QuickViewThemeSettings | null>(null);

export type QuickViewThemeSettingsProviderProps = PropsWithChildren<{
  defaultSettings?: QuickViewThemeSettings;
}>;

export const QuickViewThemeSettingsProvider = registryComponent(
  'QuickViewThemeSettingsProvider',
  ({
    children,
    defaultSettings,
    helpersRef,
  }: RegistryComponentProps<
    QuickViewThemeSettingsProviderProps,
    {
      reloadSettings: (settings?: DeepPartial<QuickViewThemeSettings>) => void;
      getSettings: () => QuickViewThemeSettings;
    }
  >) => {
    const settingsFromWindow = window?.boostSDAppConfig?.themeSettings?.quickView || {};
    const [settings, setSettings] = useState(merge({}, settingsFromWindow, defaultSettings));

    useImperativeHandle(helpersRef, () => ({
      getSettings() {
        return settings;
      },
      reloadSettings(settings) {
        const settingsFromWindow = window?.boostSDAppConfig?.themeSettings?.quickView || {};

        setSettings(merge({}, settingsFromWindow, defaultSettings, settings));
      },
    }));

    return (
      <QuickViewThemeSettingsContext.Provider value={settings}>
        {children}
      </QuickViewThemeSettingsContext.Provider>
    );
  }
);

export const useQuickViewThemeSettings = () => {
  const context = useContext(QuickViewThemeSettingsContext);
  if (!context) {
    throw Error('Use useQuickViewThemeSettings in QuickViewThemeSettingsProvider');
  }

  return context;
};
