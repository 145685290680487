import './Slide.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { createClsNameMap } from '@utils';

export type SliderSlideProps<P> = {
  payload: P;
  index: number;
  onRender: (payload: P, number: number) => React.ReactNode;
};

const clsNameMap = createClsNameMap()('slide');

const SliderSlide = <P,>({ index, payload, onRender }: SliderSlideProps<P>) => {
  return <div className={clsNameMap.root()}>{onRender(payload, index)}</div>;
};

export default registryComponent('SliderSlide', SliderSlide) as <P>(
  props: SliderSlideProps<P>
) => JSX.Element;
