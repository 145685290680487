import { get } from 'lodash-es';
import { useTranslation as _useTranslation } from 'react-i18next';

/**
 * Create a custom hook for optimizing later
 */
export const useTranslation = () => {
  return _useTranslation('translation', { i18n: get(window, 'BoostSDI18next') });
};

export default useTranslation;
