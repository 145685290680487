import { registryComponent } from '@boost-sd/components-registry/registry';
import { InnerHTMLRenderer } from '@components/InnerHTMLRenderer';
import classnames from '@utils/classnames';

export type IntegrationProductWishlistProps = {
  template?: string;
};

const IntegrationProductWishlist = ({ template }: IntegrationProductWishlistProps) => {
  if (!template) return null;

  return (
    <InnerHTMLRenderer
      className={classnames('wishlist')}
      onClick={(e) => e.preventDefault()}
      html={template}
    />
  );
};

export default registryComponent('IntegrationProductWishlist', IntegrationProductWishlist);
