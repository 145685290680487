/* eslint-disable simple-import-sort/exports */
import { clsNameMapFactory, createClassnameNameSpace } from '@boost-sd/classnames';

const classnames = createClassnameNameSpace({ prefix: 'boost-sd__' });

export const createClsNameMap = clsNameMapFactory(classnames);

export type { ModifiersTypeMap, ModifiersType } from '@boost-sd/classnames';
export { appendClassNames, mapModifiers, mergeModifiers } from '@boost-sd/classnames';

export default classnames;
