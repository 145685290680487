import { bootstrapAppHistory } from './history';
import { bootstrapAppLogger } from './logger';
import { bootstrapAppRegistry } from './registry';
import { bootstrapAppTranslation } from './translation';

export const bootstrapAppModules = () => {
  bootstrapAppRegistry();
  bootstrapAppLogger();
  bootstrapAppHistory();
  bootstrapAppTranslation();
};
