import { useCallback, useEffect, useRef } from 'react';

export type ComponentStatus = 'ready' | 'initializing' | 're-rendering' | 'unmounted';

/**
 * @description Use this hook on top of RFC
 */
export const useComponentStatus = () => {
  const statusRef = useRef<ComponentStatus>('initializing');
  const renderCountRef = useRef<number>(0);

  if (statusRef.current !== 'initializing') {
    statusRef.current = 're-rendering';
  }

  useEffect(() => {
    renderCountRef.current += 1;
    statusRef.current = 'ready';
  });

  useEffect(() => {
    return () => {
      statusRef.current = 'unmounted';
    };
  }, []);

  const getStatus = useCallback(() => {
    return statusRef.current;
  }, []);

  const getRenderCount = useCallback(() => {
    return renderCountRef.current;
  }, []);

  return {
    status: statusRef.current,
    getStatus,
    renderCount: renderCountRef.current,
    getRenderCount,
  };
};
