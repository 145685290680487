import type { CartButtonProps } from '@components/CartButton';
import { useCartThemeSettings } from '@providers/ThemeProvider/Provider/CartThemeSettings';
import { useMemo } from 'react';

export type ButtonPropsMapSettings = CartButtonProps;

export const useConnectCartButton = (type: 'checkout' | 'viewCart') => {
  const cartSettings = useCartThemeSettings();
  const buttonSettings = type === 'checkout' ? cartSettings.checkoutBtn : cartSettings.viewCartBtn;

  const props: ButtonPropsMapSettings = useMemo(() => {
    return {
      ...cartSettings.generalLayout,
      textColor: buttonSettings?.color,
      textColorOnHover: buttonSettings?.hoverColor,
      backgroundColor: buttonSettings?.backgroundColor,
      backgroundColorOnHover: buttonSettings?.hoverBackgroundColor,
      borderColor: buttonSettings?.borderColor,
      borderColorOnHover: buttonSettings?.hoverBorderColor,
      textTransform: buttonSettings?.textTransform,
    };
  }, [buttonSettings, cartSettings]);

  return {
    props,
  };
};
