import type { RegistryComponentProps } from '@boost-sd/components-registry';
import { registryComponent } from '@boost-sd/components-registry/registry';
import type { DeepPartial } from '@types';
import { merge } from 'lodash-es';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useImperativeHandle, useState } from 'react';

import type { ProductListThemeSettings } from '../types';

const ProductListThemeSettingsContext = createContext<ProductListThemeSettings | null>(null);

export type ProductListThemeSettingsProviderProps = PropsWithChildren<{
  defaultSettings?: Partial<ProductListThemeSettings>;
}>;

export const ProductListThemeSettingsProvider = registryComponent(
  'ProductListThemeSettingsProvider',
  ({
    children,
    defaultSettings,
    helpersRef,
  }: RegistryComponentProps<
    ProductListThemeSettingsProviderProps,
    {
      reloadSettings: (settings?: DeepPartial<ProductListThemeSettings>) => void;
      getSettings: () => ProductListThemeSettings;
    }
  >) => {
    const settingsFromWindow = window?.boostSDAppConfig?.themeSettings?.productList || {};
    const [settings, setSettings] = useState<ProductListThemeSettings>(
      merge({}, settingsFromWindow, defaultSettings)
    );

    useImperativeHandle(helpersRef, () => ({
      getSettings() {
        return settings;
      },
      reloadSettings(settings) {
        const settingsFromWindow = window?.boostSDAppConfig?.themeSettings?.productList || {};

        setSettings(merge({}, settingsFromWindow, defaultSettings, settings));
      },
    }));

    return (
      <ProductListThemeSettingsContext.Provider value={settings}>
        {children}
      </ProductListThemeSettingsContext.Provider>
    );
  }
);

export const useProductListThemeSettings = () => {
  const context = useContext(ProductListThemeSettingsContext);
  if (!context) {
    throw Error('Use useProductListThemeSettings in ProductListThemeSettingsProvider');
  }

  return context;
};
