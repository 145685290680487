import { useEffect } from 'react';

import useLatest from './useLatest';

type Event = HTMLElementEventMap['mousedown'] | HTMLElementEventMap['touchstart'];
type Handler = (event: Event) => unknown;

const events = ['mousedown', 'touchstart'] as const;

export default function useOnClickOutside(ref: React.RefObject<HTMLElement>, handler: Handler) {
  if (typeof document === 'undefined') {
    return;
  }

  const handlerRef = useLatest(handler);

  useEffect(() => {
    if (!handler) {
      return;
    }

    const listener = (event: Event) => {
      if (!ref.current || !handlerRef.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handlerRef.current(event);
    };

    events.forEach((event) => {
      document.addEventListener(event, listener);
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, listener);
      });
    };
  }, [!handler]);
}
