import type { PropsWithChildren } from 'react';

import type { AdditionalElementThemeSettingsProviderProps } from './Provider/AdditionalElementThemeSettings';
import { AdditionalElementThemeSettingsProvider } from './Provider/AdditionalElementThemeSettings';
import type { CartThemeSettingsProviderProps } from './Provider/CartThemeSettings';
import { CartThemeSettingsProvider } from './Provider/CartThemeSettings';
import type { ProductItemThemeSettingsProviderProps } from './Provider/ProductItemThemeSettings';
import { ProductItemThemeSettingsProvider } from './Provider/ProductItemThemeSettings';
import type { ProductListThemeSettingsProviderProps } from './Provider/ProductListThemeSettings';
import { ProductListThemeSettingsProvider } from './Provider/ProductListThemeSettings';
import type { QuickViewThemeSettingsProviderProps } from './Provider/QuickViewThemeSettings';
import { QuickViewThemeSettingsProvider } from './Provider/QuickViewThemeSettings';

export type ThemeProviderProps = PropsWithChildren<{
  productItemSettings?: ProductItemThemeSettingsProviderProps['defaultSettings'];
  quickViewSettings?: QuickViewThemeSettingsProviderProps['defaultSettings'];
  additionalElementSettings?: AdditionalElementThemeSettingsProviderProps['defaultSettings'];
  cartSettings?: CartThemeSettingsProviderProps['defaultSettings'];
  productListSettings?: ProductListThemeSettingsProviderProps['defaultSettings'];
}>;

export const ThemeProvider = ({
  productItemSettings,
  quickViewSettings,
  additionalElementSettings,
  cartSettings,
  productListSettings,
  children,
}: ThemeProviderProps) => {
  return (
    <ProductItemThemeSettingsProvider defaultSettings={productItemSettings}>
      <QuickViewThemeSettingsProvider defaultSettings={quickViewSettings}>
        <AdditionalElementThemeSettingsProvider defaultSettings={additionalElementSettings}>
          <CartThemeSettingsProvider defaultSettings={cartSettings}>
            <ProductListThemeSettingsProvider defaultSettings={productListSettings}>
              {children}
            </ProductListThemeSettingsProvider>
          </CartThemeSettingsProvider>
        </AdditionalElementThemeSettingsProvider>
      </QuickViewThemeSettingsProvider>
    </ProductItemThemeSettingsProvider>
  );
};

export default ThemeProvider;
