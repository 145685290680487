/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AnyFunction } from '@boost-sd/core-js';
import { createContext } from 'react';

import type { ElementModel } from './element';

export type RegistryComponentProps<P, Helpers extends Record<string, AnyFunction> = never> = P & {
  helpersRef?: React.Ref<Helpers>;
};

export type RenderContext<P> = {
  element?: ElementModel<P>;
  renderContextPath: string[];
  childrenContext: {
    [name: string]: (() => RenderContext<any> | null) | (() => RenderContext<any> | null)[];
  };
  registryChildrenContext<P>(name: string, context: () => RenderContext<P> | null): void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ComponentRenderingContext = createContext<RenderContext<any> | null>(null);
