import './ProductLabel.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import CustomizableNode from '@components/CustomizableNode';
import { createClsNameMap, mergeModifiers } from '@utils';
import type { ImgHTMLAttributes, ReactNode } from 'react';

const clsNameMap = createClsNameMap({
  elements: {
    img: createClsNameMap(),
    text: createClsNameMap(),
  },
  modifiers: [
    'rectangle',
    'rounded-rectangle',
    'circle',
    'image',
    'sale',
    'soldout',
    'customLabelByTag',
  ],
})('product-label');

export type ProductLabelProps = {
  labelType: 'sale' | 'soldout' | 'customLabelByTag';
  labelText?: string | ReactNode;
  displayType?: 'image' | 'text';
  imgSrc?: string;
  imgAlt?: string;
  imgAttrs?: React.DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  onRender?: '';
  shape?: 'rectangle' | 'rounded-rectangle' | 'circle';
  color?: string;
  backgroundColor?: string;
};

const ProductLabel = ({
  displayType,
  imgSrc,
  imgAlt,
  imgAttrs,
  onRender,
  color,
  backgroundColor,
  shape,
  labelText,
  labelType,
}: ProductLabelProps) => {
  const style = {
    color,
    backgroundColor,
  };

  return (
    <div
      className={mergeModifiers(clsNameMap, [
        {
          image: displayType === 'image',
        },
        shape && [shape],
        [labelType],
      ])}
      style={style}
    >
      <CustomizableNode renderer={onRender}>
        {() => {
          if (displayType === 'image') {
            return (
              <img className={clsNameMap.elm('img')} src={imgSrc} alt={imgAlt} {...imgAttrs} />
            );
          }

          return <span className={clsNameMap.elm('text')}>{labelText}</span>;
        }}
      </CustomizableNode>
    </div>
  );
};

export default registryComponent('ProductLabel', ProductLabel);
