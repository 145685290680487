import { get, isPlainObject } from 'lodash-es';

export type ClassnamesNSOptions = {
  prefix?: string;
};

export type ClassNamesNS = (...args: any[]) => string;

export type ClassNameComposerProps<Modifier, Elements> = {
  modifiers?: Modifier[];
  elements?: Elements;
};

export type ClassNameMap<
  T,
  Modifier extends keyof T,
  Elements extends Record<string, ClassNameMapFactory<unknown>>
> = {
  root: () => string;
  m: Pick<T, Modifier>;
} & { [key in keyof Elements]: ReturnType<Elements[key]> };

export type ClassNameMapFactory<ClsMap> = (rootCl: string) => ClsMap;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ModifiersType<M, HasTypeMap extends boolean = false> = M extends ClassNameMap<
  infer _T,
  infer Modifier,
  infer _Elements
>
  ? Modifier[] | (HasTypeMap extends true ? ModifiersTypeMap<M> : never)
  : [];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ModifiersTypeMap<M> = M extends ClassNameMap<infer _T, infer Modifier, infer _Elements>
  ? Partial<{ [key in Modifier]: boolean }>
  : {};

type ElementType<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: Key extends 'm'
    ? never
    : ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${ElementType<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export const appendClassNames = (classNames: string, ...newClassNames: (string | undefined)[]) => {
  if (!newClassNames) return classNames;

  return [classNames, ...newClassNames].join(' ').trim();
};

const classnamesItemValue = (raw: unknown, prefix: string): string => {
  const rawType = typeof raw;
  let value = '';

  if (rawType === 'string' || raw === 'number') {
    value += `${prefix}${raw}`;
  } else if (rawType === 'object') {
    if (Array.isArray(raw)) {
      const rawLength = raw.length;

      for (let i = 0; i < rawLength; i++) {
        if (raw[i]) {
          const itemValue = classnamesItemValue(raw[i], prefix);

          if (itemValue) {
            value += value ? ` ${value}` : value;
          }
        }
      }
    } else {
      const rawObj = raw as Record<string, unknown>;
      for (const classnameItem in rawObj) {
        if (rawObj[classnameItem]) {
          value += value ? ` ${prefix}${classnameItem}` : `${prefix}${classnameItem}`;
        }
      }
    }
  }

  return value;
};

export const createClassnameNameSpace = (options?: ClassnamesNSOptions): ClassNamesNS => {
  const { prefix = '' } = options || {};

  /**
   * @returns A string as classname which has been combined
   *
   * @example
   *```
   * classnames('filter-option', color && `filter-option--${color}`, hidden && 'filter-option--hidden', {
   *   'filter-option--scrollable': scrollable
   * })
   *```
   */
  return function clsNameNamespace(...args: any[]): string {
    let i = 0;
    let classnameResult = '';
    const argsLength = args.length;

    while (i < argsLength) {
      const classnameRawInput = args[i];
      const classnameValue = classnamesItemValue(classnameRawInput, prefix);

      if (classnameValue) {
        classnameResult = appendClassNames(classnameResult, classnameValue);
      }

      i += 1;
    }

    return classnameResult;
  };
};

export const clsNameMapFactory = (classNameNS: ClassNamesNS) => {
  const createClsNameMap = <
    T extends Record<string, string>,
    Modifier extends string,
    Elements extends Record<string, ClassNameMapFactory<unknown>>
  >({ modifiers, elements }: ClassNameComposerProps<Modifier, Elements> = {}) => {
    return function (rootCl: string) {
      const obj = {
        root: () => classNameNS(rootCl),
        m: {},
      } as ClassNameMap<T, Modifier, Elements>;

      if (modifiers) {
        modifiers.forEach((modifier) => {
          Object.assign(obj.m, {
            [modifier]: classNameNS(`${rootCl}--${modifier}`),
          });
        });
      }

      if (elements) {
        for (const element in elements) {
          // eslint-disable-next-line no-prototype-builtins
          if (elements.hasOwnProperty(element)) {
            const elementRootCl = `${rootCl}-${element}`;
            Object.assign(obj, {
              [element]: elements[element](elementRootCl),
            });
          }
        }
      }

      return {
        ...obj,
        elm: (path: ElementType<ClassNameMap<T, Modifier, Elements>>) =>
          (get(obj, path) as ClassNameMap<T, Modifier, Elements>).root(),
      };
    };
  };

  return createClsNameMap;
};

export const mapModifiers = <
  T extends Record<string, string>,
  Modifier extends string,
  Elements extends Record<string, ClassNameMapFactory<unknown>>,
  ModifierKeys extends ModifiersType<ClassNameMap<T, Modifier, Elements>>
>(
  clsNameMap: ClassNameMap<T, Modifier, Elements>,
  modifiers?: ModifierKeys | Partial<{ [key in ModifierKeys[number]]: boolean }>,
  excludeBase?: boolean
) => {
  let classNames = excludeBase ? '' : clsNameMap.root();
  if (!modifiers) return classNames;

  if (Array.isArray(modifiers)) {
    modifiers.forEach((modifier) => {
      const modifierClassName = clsNameMap.m[modifier];
      if (modifierClassName) {
        classNames = appendClassNames(classNames, modifierClassName);
      }
    });
  } else if (isPlainObject(modifiers)) {
    Object.keys(modifiers).forEach((modifier) => {
      if (modifiers[modifier as ModifierKeys[number]]) {
        classNames = appendClassNames(classNames, clsNameMap.m[modifier as ModifierKeys[number]]);
      }
    });
  }

  return classNames;
};

export const mergeModifiers = <
  T extends Record<string, string>,
  Modifier extends string,
  Elements extends Record<string, ClassNameMapFactory<unknown>>,
  ModifierKeys extends ModifiersType<ClassNameMap<T, Modifier, Elements>>,
  MergedModifiers extends Partial<{ [key in ModifierKeys[number]]: boolean }>
>(
  clsNameMap: ClassNameMap<T, Modifier, Elements>,
  modifiersSet: Array<
    ModifierKeys | Partial<{ [key in ModifierKeys[number]]: boolean }> | undefined
  >,
  excludeBase?: boolean
) => {
  const mergedModifiers = {} as MergedModifiers;

  if (modifiersSet) {
    modifiersSet.forEach((modifiers) => {
      if (!modifiers) return;

      if (Array.isArray(modifiers)) {
        Object.assign(
          mergedModifiers,
          Object.fromEntries(modifiers.map((key: ModifierKeys[number]) => [key, true]))
        );
      }

      if (isPlainObject(modifiers)) {
        Object.assign(mergedModifiers, modifiers);
      }
    });
  }

  return mapModifiers(clsNameMap, mergedModifiers, excludeBase);
};
