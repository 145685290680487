import './ProductSwatch.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ReactNodeRenderer } from '@components/CustomizableNode';
import CustomizableNode from '@components/CustomizableNode';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useTranslation from '@hooks/useTranslation';
import { createClsNameMap, mapModifiers, mergeModifiers } from '@utils';
import { useImperativeHandle, useRef, useState } from 'react';

import type { ProductSwatchOptionProps, ProductSwatchOptionValue } from './Option';
import ProductSwatchOption from './Option';

const clsNameMap = createClsNameMap({
  elements: {
    title: createClsNameMap(),
    options: createClsNameMap({
      modifiers: ['vertical', 'horizontal'],
    }),
    more: createClsNameMap({
      modifiers: ['non-radius'],
    }),
    'selected-value': createClsNameMap(),
  },
  modifiers: ['full-width'],
})('product-swatch');

export type ProductSwatchHandler<OptionItemValue extends ProductSwatchOptionValue = string> = {
  getSelectedOption: () => OptionItemValue | undefined;
  selectOption: (optionValue: OptionItemValue) => void;
};

export type ProductSwatchProps<OptionItemValue extends ProductSwatchOptionValue = string> = {
  options: Array<ProductSwatchOptionProps<OptionItemValue>>;
  label?: ReactNodeRenderer;
  direction?: 'vertical' | 'horizontal';
  readonly?: boolean;
  onSelect?: (selectedOption: OptionItemValue, image?: string) => unknown;
  onHover?: (selectedOption: OptionItemValue, image?: string) => unknown;
  onCLickOutSide?: (selectedOption?: OptionItemValue) => unknown;
  onBlur?: () => unknown;
  onItemRender?: ReactNodeRenderer<ProductSwatchOptionProps<OptionItemValue>>;
  itemProps?: Omit<ProductSwatchOptionProps<OptionItemValue>, 'options' | 'onSelect' | 'selected'>;
  defaultSelectedValue?: OptionItemValue;
  handler?: React.MutableRefObject<ProductSwatchHandler<OptionItemValue> | null>;
  justifyContent?: React.CSSProperties['justifyContent'];
  maxItem?: number;
  itemShape?: 'box' | 'circle';
  isPopupSelectOption?: boolean;
  isQuickView?: boolean;
  swatchButtonTextKey?: string;
  isFullWidth?: boolean;
  selectOptionOnLabel?: boolean;
};

const ProductSwatch = <ItemValue extends ProductSwatchOptionValue = string>({
  options,
  label,
  direction,
  readonly,
  onSelect,
  onHover,
  onBlur,
  onCLickOutSide,
  onItemRender,
  itemProps,
  defaultSelectedValue,
  handler,
  justifyContent,
  maxItem,
  itemShape,
  isPopupSelectOption,
  isQuickView,
  swatchButtonTextKey,
  isFullWidth,
  selectOptionOnLabel,
}: ProductSwatchProps<ItemValue>) => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    selectedValue: defaultSelectedValue,
  });

  const swatchContainerRef = useRef<HTMLDivElement | null>(null);

  const onSelectOption = (value: ItemValue, image?: string) => {
    if (readonly) return;

    if (onSelect) {
      onSelect(value, image);
    }

    setState((prevState) => ({
      ...prevState,
      selectedValue: value,
    }));
  };

  useImperativeHandle(handler, () => ({
    getSelectedOption: () => state.selectedValue,
    selectOption: onSelectOption,
  }));

  const style: React.CSSProperties = {
    justifyContent,
  };

  const optionsStyle: React.CSSProperties = {
    justifyContent,
  };

  useOnClickOutside(swatchContainerRef, () => {
    if (onCLickOutSide) onCLickOutSide(state.selectedValue);
  });

  return (
    <div
      style={style}
      className={mapModifiers(clsNameMap, { 'full-width': isFullWidth })}
      ref={swatchContainerRef}
    >
      {label != null && (
        <div className={clsNameMap.elm('title')}>
          <CustomizableNode renderer={label} />
          {selectOptionOnLabel && <span>: {state.selectedValue}</span>}
        </div>
      )}
      <div
        style={optionsStyle}
        className={mapModifiers(clsNameMap.options, direction && [direction])}
      >
        {options.slice(0, maxItem).map((option) => (
          <CustomizableNode
            key={option.value}
            renderer={onItemRender}
            payload={{
              ...itemProps,
              ...option,
              onSelect: onSelectOption,
              onHover,
              onBlur,
              selected: option.value === state.selectedValue,
              isPopupSelectOption,
              isQuickView,
            }}
          >
            {(payload) => <ProductSwatchOption {...payload} displayType={itemShape} />}
          </CustomizableNode>
        ))}
        {maxItem && options.length > maxItem && (
          <button
            type='button'
            className={mergeModifiers(clsNameMap.more, [
              {
                'non-radius': itemShape === 'box',
              },
            ])}
          >
            {swatchButtonTextKey
              ? t(swatchButtonTextKey, {
                  count: options.length - maxItem,
                })
              : `+${options.length - maxItem}`}
          </button>
        )}
      </div>
    </div>
  );
};
export default registryComponent('ProductSwatch', ProductSwatch) as <
  ItemValue extends ProductSwatchOptionValue = string
>(
  props: ProductSwatchProps<ItemValue>
) => JSX.Element;
