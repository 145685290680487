import './styles.scss';

import { bootstrap } from '@boost-sd/app';

import { initCart } from './cart';
import themeSettings from './defaultSettings.json';

(function ensureSettings() {
  window.boostSDAppConfig.themeSettings = Object.assign(
    {},
    themeSettings,
    window.boostSDAppConfig.themeSettings
  );
})();

bootstrap();
initCart();
