import type { RegistryComponentProps } from '@boost-sd/components-registry';
import { registryComponent } from '@boost-sd/components-registry/registry';
import type { DeepPartial } from '@types';
import { merge } from 'lodash-es';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useImperativeHandle, useState } from 'react';

import type { AdditionalElementThemeSettings } from '../types';

const AdditionalElementThemeSettingsContext = createContext<AdditionalElementThemeSettings | null>(
  null
);

export type AdditionalElementThemeSettingsProviderProps = PropsWithChildren<{
  defaultSettings?: Partial<AdditionalElementThemeSettings>;
}>;

export const defaultAdditionalElementSettings = {
  showLimitList: [8, 16, 24, 32, 48],
  sortingList: [
    'relevance',
    'best-selling',
    'manual',
    'price-ascending',
    'price-descending',
    'title-ascending',
    'title-descending',
    'created-descending',
    'created-ascending',
  ],
};

export const AdditionalElementThemeSettingsProvider = registryComponent(
  'AdditionalElementThemeSettingsProvider',
  ({
    children,
    defaultSettings,
    helpersRef,
  }: RegistryComponentProps<
    AdditionalElementThemeSettingsProviderProps,
    {
      reloadSettings: (settings?: DeepPartial<AdditionalElementThemeSettings>) => void;
      getSettings: () => AdditionalElementThemeSettings;
    }
  >) => {
    const { customSortingList } = window.boostSDAppConfig?.additionalElementSettings || {};

    const sortingList = customSortingList
      ? customSortingList.split('|')
      : defaultAdditionalElementSettings.sortingList;

    const { showLimitList } = defaultAdditionalElementSettings;

    const settingsFromWindow = window?.boostSDAppConfig?.themeSettings?.additionalElements || {};

    const [settings, setSettings] = useState(
      merge(
        {},
        settingsFromWindow,
        {
          toolbar: {
            showLimitList,
            sortingList,
          },
        },
        defaultSettings
      )
    );

    useImperativeHandle(helpersRef, () => ({
      getSettings() {
        return settings;
      },
      reloadSettings(settings) {
        const settingsFromWindow =
          window?.boostSDAppConfig?.themeSettings?.additionalElements || {};

        setSettings(
          merge(
            {},
            settingsFromWindow,
            {
              toolbar: {
                showLimitList,
                sortingList,
              },
            },
            defaultSettings,
            settings
          )
        );
      },
    }));

    return (
      <AdditionalElementThemeSettingsContext.Provider value={settings}>
        {children}
      </AdditionalElementThemeSettingsContext.Provider>
    );
  }
);

export const useAdditionalElementThemeSettings = () => {
  const context = useContext(AdditionalElementThemeSettingsContext);
  if (!context) {
    throw Error('Use useAdditionalElementThemeSettings in AdditionalElementThemeSettingsProvider');
  }

  return context;
};
